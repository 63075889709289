import React from 'react';
import { Header } from '../common/Header';
import { Footer } from '../common/Footer';
import { useMediaQuery } from 'react-responsive';
import { BlockingOverlay } from '../specific/BlockingOverlay';

export const Layout = ({ children, setIsNavAccess }) => {
  const isSmallWidth = useMediaQuery({ maxWidth: 1400 });
  const isSmallHeight = useMediaQuery({ maxHeight: 800 });
  const isSmallScreen = isSmallWidth || isSmallHeight;

  const handleNavClick = () => {
    setIsNavAccess(true);
  };


  return (
    <>
      {!isSmallScreen && (
        <>
          <Header onNavClick={handleNavClick} />
          <main style={{ paddingTop: "6rem" }}>{children}</main>
          <Footer onNavClick={handleNavClick} />
        </>
      )}
      <BlockingOverlay />
    </>
  );
};


