import React, { useState } from "react";
import { BlobServiceClient } from "@azure/storage-blob";

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const uploadImageToAzure = async (file) => {
    const accountName = "your-storage-account-name";
    const containerName = "your-container-name";
    const sasToken = "your-sas-token";

    const blobServiceClient = new BlobServiceClient(
      `https://${accountName}.blob.core.windows.net/?${sasToken}`
    );

    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(file.name);

    try {
      await blockBlobClient.uploadBrowserData(file);
      const url = `https://${accountName}.blob.core.windows.net/${containerName}/${file.name}`;
      setImageUrl(url); // Set the uploaded image URL
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleFileUpload = () => {
    if (file) {
      uploadImageToAzure(file);
    } else {
      console.log("No file selected");
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleFileUpload}>Upload File</button>
      {imageUrl && (
        <div>
          <h3>Uploaded Image:</h3>
          <img src={imageUrl} alt="Uploaded File" style={{ width: "300px" }} />
        </div>
      )}
    </div>
  );
};

export default FileUpload;
