import React from "react";
import "../assets/styles/termsAndConditionStyle.css";
import {getDynamicImageUrl} from "../components/specific/imageBlob";


const TermsAndCondition = () => {

  const themeImg = getDynamicImageUrl("others/termsThemeImg.svg");
  const themeIcon = getDynamicImageUrl("others/termsImg.svg");
  return (

    <div className="terms_parent_container">
      <div className="terms_container">
        <div className="terms_container_1">
          <p className="main_heading">Terms & Conditions</p>
         
          <section>
            <p className="section_heading">1. Scope</p>
            <p className="section_data">
              1.1 These Terms of Use of Sciverse (”Sciverse Solutions Pvt Ltd”)
              govern the use of the internet pages provided by Sciverse and/or
              its subsidiaries (the "Sciverse Solutions Pvt Ltd Website"). Any
              use of the Sciverse Website is only permitted on the basis of
              these Terms of Use, which may be amended, modified or replaced by
              other terms and conditions, e.g. for the purchase of products and
              services, at the sole discretion of Sciverse. The User accepts
              these Terms of Use in their then current version each time when
              logging-in or, if a log-in is not required, when starting to use
              the Sciverse Website. <br /> 1.2 If the User while using the
              Sciverse Website acts as business customer, i.e. that it is not
              acting for purposes which are outside its trade, business or
              profession, or that it acts as administration customer, § 312i
              para. 1 sentence 1 no. 1 - 3 of the German Civil Code does not
              apply.
            </p>
          </section>
          <section>
            <p className="section_heading">2. Services</p>
            <p className="section_data">
              2.1 Sciverse offers on the Sciverse Website specific information,
              software and related documentation, as the case may be, for
              viewing or downloading. <br />
              2.2 Sciverse may at any time cease the operation of the entire
              Sciverse Website or parts thereof. Sciverse shall not accept any
              responsibility and shall not be liable for the continuous
              availability of the Sciverse Website.
              <br />
              2.3 Sciverse may deny Users access to the Sciverse Website at any
              time without notice and without providing reasons.
            </p>
          </section>
          <section>
            <p className="section_heading">3. Registration, Password</p>
            <p className="section_data">
              3.1 Some pages of the Sciverse Website may be password protected.
              For reasons of safety and security of business transactions, only
              registered Users may access such pages. Sciverse reserves the
              right to deny registration to any User at its sole discretion.
              Sciverse also reserves the right to subject any part of the
              Sciverse Website to a registration requirement even if such part
              had previously been freely accessible. Sciverse shall be entitled
              to prevent Users from accessing one or more password-protected
              areas by blocking User Data (as defined below) at any time and
              without having to provide reasons. The foregoing in particular
              applies if a User
            </p>
            <ul>
              <li>Uses a false identity for the purpose of misleading others;</li>
              <li>Violates these Terms of Use or neglects its duty of care with regard to User Data; or</li>
              <li>Does not use the Sciverse Website for a longer period of time.</li>
            </ul>
            <p className="section_data">
              3.2 When registering, the User shall provide accurate information, and
              shall inform Sciverse online and without undue delay if such
              information changes over time. The User shall ensure that the
              e-mail address supplied to Sciverse is current and effective for
              contacting the User.
              <br />
              3.3 The User will be provided with an access code upon registration
              which comprises a User ID and a password ("User Data"). On first
              access the User shall change the password received from Sciverse
              into a password known only to the User. The User Data permits the
              User to view or change its data or to withdraw its consent to data
              processing, as the case may be.
              <br />
              3.4 The User is liable for all transactions and other activities
              carried out under its User Data and shall ensure that such User
              Data is not accessible by third parties. The User shall log-off
              from all password protected websites at the end of each online
              session. If a User becomes aware of any misuse of its User Data by
              third parties, Sciverse must be informed in writing or by e-mail
              without undue delay.
              <br />
              3.5 After receipt of a notice under section 3.4, Sciverse will deny the
              User access to all password-protected areas under the affected
              User Data. Access will only be permitted again upon corresponding
              application to Sciverse or a new registration of the User.
              <br />
              3.6 The User may at any time request in writing the deletion of its
              registration, provided that such deletion does not impair or
              prevent the proper performance of a contractual relationship.
              Following such request Sciverse will delete all User Data and any
              other personally identifiable data of the User in compliance with
              applicable legislation as soon as such data are not required anymore.
            </p>
          </section>
          <section>
            <p className="section_heading">
              4. Rights of Use to Information, Software and Documentation
            </p>
            <p className="section_data">
              4.1 Any use of information, software and documentation provided by
              Sciverse on the Sciverse Website, and of any updates thereof, (the
              “Sciverse Solutions Pvt Ltd Contents”) shall be subject to these
              Terms of Use or the applicable license terms agreed with Sciverse,
              provided that separately agreed license terms shall always prevail
              over these Terms of Use.
              <br />
              4.2 Sciverse grants the User a non-exclusive and non-transferable
              right to use the Sciverse Contents to the extent agreed between
              Sciverse and the User or, if there is no such agreement, in
              accordance with the purpose obviously intended by Sciverse in
              making the same available.
              <br />
              4.3 Software shall be made available in object code only at no
              expense to the User. Source code shall not be made available
              except for source code of open source software, if and to the
              extent applicable license conditions prevail over these Terms of
              Use and require the disclosure of the source code. In such case
              Sciverse shall make the source code available in return for
              payment of the costs.
              <br />
              4.4 The User shall not distribute the Sciverse Contents to third
              parties, or make them available in any other way. Unless required
              by applicable mandatory laws, the User shall not modify,
              disassemble, reverse engineer or decompile software or any parts
              thereof that are part of Sciverse Contents. The User may make one
              backup copy of software if required for securing its use.
              <br />
              4.5 The Sciverse Contents are protected by copyright laws,
              international copyright treaties and other laws and conventions on
              intellectual property and the User shall comply therewith. In
              particular, the User shall not remove any alphanumeric codes,
              marks or copyright notices from the Sciverse Contents.
            </p>
          </section>
          <section>
            <p className="section_heading">5. Intellectual Property</p>
            <p className="section_data">
              5.1 Notwithstanding the provisions of section 4 of these Terms of
              Use, any brand names and other Sciverse Contents made available on
              the Sciverse Website may not be modified, copied, reproduced,
              sold, rented, supplemented or used in any other way without the
              prior written permission of Sciverse.
              <br />
              5.2 These Terms of Use shall not confer any rights in copyrights or
              other intellectual property to the User, including rights in
              copyrights, brands, patents or utility models, except as
              expressly granted herein, and there shall be no implied obligation
              of Sciverse to do so.
            </p>
          </section>
          <section>
            <p className="section_heading">6. Duties of the User</p>
            <p className="section_data">
            6.1 While using the Sciverse Website the User shall not <br/>harm or infringe the personal rights of third parties;<br/>breach public morality;
            </p>
            <ul>
              <li>Uses a false identity for the purpose of misleading others;</li>
              <li>Violates these Terms of Use or neglects its duty of care with regard to User Data; or</li>
              <li>Does not use the Sciverse Website for a longer period of time.</li>
            </ul>
            <p className="section_data">
            6.2 If the User breaches any obligation arising from these Terms of Use, and without prejudice to any other remedies Sciverse may have, such User will immediately be denied access to the Sciverse Website.
            </p>
          </section>
          <section>
            <p className="section_heading">7. Quality or title defects</p>
            <p className="section_data">
            7.1 Any warranties or liability for defects of the Sciverse Contents as to quality or title, express or implied, are excluded, including warranties of correctness, completeness, merchantability and/or fitness for purpose, or of absence of third party rights, except for cases of willful misconduct, fraud, gross negligence, personal injury or death.<br/>7.2 The Sciverse Contents may contain general descriptions of the technical possibilities of individual products which may not be available in certain cases (e.g. due to product changes). Any such descriptions may not be understood as contractual warranties and the User accepts that the required performance of products shall be as agreed at the time of purchase.
            </p>
          </section>
          <section>
            <p className="section_heading">8. Export Controls</p>
            <p className="section_data">
            8.1 The liability of Sciverse for defects in relation to quality and title shall be determined in accordance with the provisions of section 8 of these conditions. Any further liability of Sciverse is excluded unless and to the extent required by applicable mandatory law, e.g. in cases of willful misconduct, fraud, gross negligence, personal injury or death, failure to achieve guaranteed characteristics, fraudulent concealment of a defect or in case of breach of fundamental contractual obligations. The damages in case of breach of fundamental contractual obligations is limited to the contract-typical, foreseeable damage if there is no willful misconduct or gross negligence.<br/>8.2 Although Sciverse endeavors to keep the Sciverse Website free from viruses and other malware, Sciverse cannot give any guarantee to this effect. The User shall take all measures required for its own protection to ensure appropriate security and, in particular, use a virus scanner before downloading any Sciverse Contents. In addition, the User shall take reasonable security precautions and, in particular, use a virus scanner before uploading any contents onto the Sciverse Website.8.3 Sections 8.1 and 8.2 do not intend nor imply any changes to the burden of proof to the User's disadvantage.
            </p>
          </section> <section>
            <p className="section_heading">9. Data Privacy Protection</p>
            <p className="section_data">
            For the collection, use and processing of personally identifiable data of the User on or in connection with the Sciverse Website, Sciverse shall comply with applicable laws on data privacy protection and the Sciverse Website Data Protection Privacy Policy, which is available per hyperlink on the Sciverse Website.
            </p>
          </section> <section>
            <p className="section_heading">10. Supplementary Agreements, Place of Jurisdiction, Applicable Law</p>
            <p className="section_data">
            10.1 Any supplementary agreement requires the written form.<br/>10.2 Sciverse makes no representation that the Sciverse Contents offered on the Sciverse Website are appropriate or available for viewing or downloading at locations outside of India. If Users access the Sciverse Website from other countries than India, such Users are exclusively responsible for their compliance with local laws. Access to Sciverse Contents from countries where such contents are illegal is prohibited.<br/>10.3 These Terms of Use shall be governed by, and all disputes relating to or in connection with these Terms of Use or their subject matter shall be resolved in accordance with the laws of India, to the exclusion of its conflict of laws rules. The application of the United Nations Convention on Contracts for the International Sales of Goods (CISG) of 11 April 1980 is excluded.
            </p>
          </section>
          </div>
        <div className="terms_container_2">
            <img src={themeImg}/>
            <img src={themeIcon}/>
        </div>
       
        </div>
      </div>
  
  );
};

export default TermsAndCondition;


