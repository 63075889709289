import React,{useState, useEffect} from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
  useLocation,
  Navigate
} from "react-router-dom";
import { Layout } from "../components/layout/Layout";
import { Home } from "../pages/Home";
import { Solutions } from "../pages/Solutions";
import { AiAndSoftware } from "../pages/AiAndSoftware";
import { LetsCollaborate } from "../pages/LetsCollaborate";
import { OurWork } from "../pages/OurWork";
import { ContactUs } from "../pages/ContactUs";
import { Services } from "../pages/Services";
import { Career } from "../pages/Career";
import { CareerApplication } from "../pages/CareerApplication";
import { Survey } from "../pages/Survey";
import OurTeam from "../pages/OurTeam";
import LetsConnect from "../pages/LetsConnect";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsAndCondition from "../pages/TermsAndCondition";
import ScrollToTop from "../components/common/ScrollTop";
import { Practice } from "../pages/Practice";
const AppRoutes = () => {
  const [isNavAccess, setIsNavAccess] = useState(false);
  //Custom Hook to detect route changes
  const usePathname = () => {
    const location = useLocation();
    useEffect(()=>{
      if(!isNavAccess){
        setIsNavAccess(false)
      }
    },[location]);
    return location.pathname;
  };
  usePathname();
  return (
    <>
      <ScrollToTop />
      <Routes>
          <Route path="/" element={<LayoutWrapper setIsNavAccess={setIsNavAccess} />}>
          <Route index element={<Home />} />

          {/* Protected Routes */}
          <Route path="/solutions" element={isNavAccess ? <Solutions /> : <Navigate to="/" />} />          
          <Route path="/services" element={ isNavAccess ? <Services /> : <Navigate to="/" />} />
          <Route path="/aisoftware" element={isNavAccess ? <AiAndSoftware /> : <Navigate to="/" />} />
          <Route path="/collaborate" element={isNavAccess ? <LetsCollaborate />  : <Navigate to="/" />} />
          <Route path="/work" element={isNavAccess ? <OurWork />  : <Navigate to="/" />} />
          <Route path="/contact" element={isNavAccess ? <LetsConnect />  : <Navigate to="/" />} />
          <Route path="/career" element={isNavAccess ? <Career />  : <Navigate to="/" />} />
          <Route path="/application" element={isNavAccess ? <CareerApplication />  : <Navigate to="/" />} />
          <Route path="/survey" element={isNavAccess ? <Survey />  : <Navigate to="/" />} />
          <Route path="/ourteam" element={isNavAccess ? <OurTeam />  : <Navigate to="/" />} />
          <Route path="/privacypolicy" element={isNavAccess ? <PrivacyPolicy />  : <Navigate to="/" />} />
          <Route path="/termsandcondition" element={isNavAccess ? <TermsAndCondition />  : <Navigate to="/" />} />
          <Route path="/p" element={isNavAccess ? <Practice />  : <Navigate to="/" />} />

           {/* Default redirect for unmatched paths */}
           <Route path="*" element={<Navigate to="/" />} />

        </Route>
      </Routes>
    </>
  );
};

const LayoutWrapper = ({setIsNavAccess}) => {
  return (
    <Layout setIsNavAccess={setIsNavAccess}>

      <Outlet />
    </Layout>
  );
};

export default AppRoutes;
