import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "../../assets/styles/header.css";
import {getDynamicImageUrl} from "../specific/imageBlob";

export const Header = ({onNavClick}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeItem, setActiveItem] = useState("");
  const logoUrl = getDynamicImageUrl("header/sciverse_logo.svg")
  const navIcon = getDynamicImageUrl("header/navIcon.svg")
  const downArrowIcon = getDynamicImageUrl("others/keyboard_arrow_down.svg")

  useEffect(() => {
    const currentPath = location.pathname.substring(1);
    setActiveItem(currentPath);
  }, [location]);

  const handleItemClick = (item) => {
    setActiveItem(item);
    navigate(`/${item}`);
  };

  return (
    <div className="header_container">
      <header>
        <span>
          <img onClick={() => navigate("/")} src={logoUrl} alt="Logo" />
        </span>
        <nav>
          <ul>
            <li 
            // onClick={() => handleItemClick("solutions")}
            >
              <Link
                 className={`font-Nunito-400 ${
                  activeItem === "solutions" ? "nav-items active" : "nav-items"
                }`}
                style={{
                  fontWeight: activeItem === "solutions" ? "500" : "300",
                  display: "inline-block",
                  height: "50px",
                  cursor:"pointer"
                }}
                onClick={onNavClick}
                to="/solutions"
              >
                Solutions
              </Link>
              {activeItem === "solutions" && (
                <img
                  src={navIcon}
                  alt="Active"
                  style={{
                    position: "absolute",
                    top: "-28px",
                    left: "42%",
                    transform: "translateX(-50%)",
                  }}
                />
              )}
            </li>

            <li
              onMouseEnter={() => setShowDropdown(true)}
              onMouseLeave={() => setShowDropdown(false)}
              // onClick={() => handleItemClick("services")}
            >
              <Link
                to="/services"
                className={`font-Nunito-400 ${
                  activeItem === "services" ? "nav-items active" : "nav-items"
                }`}
                style={{
                  fontWeight: activeItem === "services" ? "500" : "300",
                  display: "inline-block",
                  height: "50px",
                }}
                onClick={onNavClick}

                // className="font-Nunito-400 "
              >
                Services
                <img  
                style={{position:"absolute",top:"0.4rem", right:"-2rem"}}
                src={downArrowIcon} />
              </Link>
              {activeItem === "services" && (
                <img
                  src={navIcon}
                  alt="Active"
                  style={{
                    position: "absolute",
                    top: "-28px",
                    left: "40%",
                    transform: "translateX(-50%)",
                  }}
                />
              )}
              {showDropdown && (
                <ul className="dropdown">
                  <li 
                  // onClick={() => handleItemClick("aisoftware")}
                  >
                    <Link
                      to="/aisoftware"
                      style={{ color: activeItem === "aisoftware" ? "#0B84CC" : "" }}
                      className="font-Nunito-400 "
                      onClick={onNavClick}

                    >
                      AI and Software
                    </Link>
                  </li>
                  <li 
                  // onClick={() => handleItemClick("collaborate")}
                  >
                    <Link
                      // to="/collaborate"
                      style={{ color: activeItem === "collaborate" ? "#0B84CC" : "",
                        cursor:"not-allowed",
                       }}
                      className="font-Nunito-400 "
                onClick={onNavClick}

                    >
                      Biosensors and Microfluidics
                    </Link>
                  </li>
                  <li 
                  // onClick={() => handleItemClick("design")}
                  >
                    <Link
                onClick={onNavClick}

                      to="#!"
                      style={{ color: activeItem === "design" ? "#0B84CC" : "",

                        cursor:"not-allowed",

                       }}
                      className="font-Nunito-400 "
                    >
                      Design
                    </Link>
                  </li>
                  <li
                    // onClick={() => handleItemClick("product-engineering")}
                    style={{ borderBottom: "none" }}
                  >
                    <Link
                onClick={onNavClick}

                      to="#!"
                      style={{
                        color: activeItem === "product-engineering" ? "#0B84CC" : "",
                        cursor:"not-allowed",

                      }}
                      className="font-Nunito-400 "
                    >
                      Product Engineering
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            <li
            //  onClick={() => handleItemClick("work")}
            style={{width:"170px"}}
             >
              <Link
                onClick={onNavClick}

                  className={`font-Nunito-400 ${
                    activeItem === "work" ? "nav-items active" : "nav-items"
                  }`}
                style={{
                  fontWeight: activeItem === "work" ? "500" : "300",
                  display: "inline-block",
                  height: "50px",
                  cursor:"not-allowed",
                  whiteSpace:"nowrap"
                }}
                // to="/work"
              >
                Case Studies
              </Link>
              {activeItem === "work" && (
                <img
                  src={navIcon}
                  alt="Active"
                  style={{
                    position: "absolute",
                    top: "-28px",
                    left: "42%",
                    transform: "translateX(-50%)",
                  }}
                />
              )}
            </li>

            <li
             onClick={() => handleItemClick("contact")}
             >
              <Link
                onClick={onNavClick}

                className={`font-Nunito-400 ${
                  activeItem === "contact" ? "nav-items active" : "nav-items"
                }`}
                style={{
                  fontWeight: activeItem === "contact" ? "500" : "300",
                  display: "inline-block",
                  height: "50px",
                  whiteSpace: "nowrap",
                  cursor: "pointer",
                }}
                to="/contact"
              >
                Contact Us
              </Link>
              {activeItem === "contact" && (
                <img
                  src={navIcon}
                  alt="Active"
                  style={{
                    position: "absolute",
                    top: "-32px",
                    left: "42%",
                    transform: "translateX(-50%)",
                  }}
                />
              )}
            </li>
          </ul>
        </nav>
      </header>
    </div>
  );
};
