import React from 'react';
import FileUpload from '../components/specific/FileUpload';

export const Practice = () => {
    const myData = { item1: 'This is item 1', item2: 'This is item 2', item3: 'This is item 3', item4: 'This is item 4', item5: 'This is item 5', item6: 'This is item 6', item7: 'This is item 7', item8: 'This is item 8', item11: 'This is item 1', item21: 'This is item 2', item31: 'This is item 3', item41: 'This is item 4', item51: 'This is item 5', item61: 'This is item 6', item71: 'This is item 7' };

  const styles = {
    container: {
      width: '100%',
      height: '400px', // Adjust height as needed
      overflowX: 'auto', // Scrollable in x-axis
      overflowY: 'hidden', // Disable vertical scrolling
      border: '1px solid #ccc',
      padding: '10px',
    },
    gridContainer: {
      display: 'grid',
      gridAutoFlow: 'column', // Items flow vertically first, then to the next column
      gridTemplateRows: 'repeat(2, 1fr)', // Always 2 rows
      gridGap: '10px',
      width: 'max-content', // Prevents grid from expanding to full container width
    },
    item: {
      backgroundColor: '#f4f4f4',
      padding: '10px',
      borderRadius: '5px',
      boxShadow: '0 0 5px rgba(0,0,0,0.1)',
      textAlign: 'center',
      minWidth: '550px', // Set the minimum width for each item
    },
  };

  return (
    <div style={styles.container}>
      <FileUpload />
      <div style={styles.gridContainer}>
        {Object.keys(myData).map((key) => (
          <div key={key} style={styles.item}>
            <h3>{key}</h3>
            <p>{myData[key]}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

