import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { getDynamicImageUrl } from "../components/specific/imageBlob";
import "../assets/styles/solutions.css";

export const Solutions = () => {
  const imagesCount = 6;
  const navigate = useNavigate();

  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const imageNames = [];
    for (let i = 1; i <= imagesCount; i++) {
      imageNames.push(`solutions/image${i}.svg`);
    }
    const urls = imageNames.map((name) => getDynamicImageUrl(name));
    setImageUrls(urls);
  }, []);

  return (
    <div className="solutions_parent_container">
      <div className="solutions_section_1">
        <p className="font-Montserrat-700">
          Unlocking the Future of Diagnostics, one platform solution at a
          time...{" "}
        </p>
        <div className="solutions_section_1_image_container">
          <img className="solutions_img_2" src={imageUrls[1]} />
          <img className="solutions_img_1" src={imageUrls[0]} />
          <img className="solutions_img_3" src={imageUrls[2]} />
          <img className="solutions_img_4" src={imageUrls[3]} />
        </div>
      </div>
      <div className="solutions_section_2">
        <div className="solutions_section_2_1">
          <p className="font-Nunito-700">
            Not just Devices, Complete diagnostic Ecosystems
          </p>
          <p className="font-Nunito-400">
            In today’s rapidly evolving diagnostic landscape, integration is no
            longer just a convenience—it’s a necessity. Our innovative platform
            solutions go beyond providing a standalone device; they offer a
            cohesive ecosystems where every component works in harmony to
            streamline your entire workflow. From the precise engineering of the
            hardware to the seamless functionality of the smart cloud portal and
            the proactive services that keep everything running smoothly, each
            part of our platform is designed to enhance performance and optimize
            results.
            <br />
            This level of integration means your lab, clinics, etc. are no
            longer limited by the constraints of individual devices or manual
            processes. Instead, you gain access to a comprehensive platform that
            empowers you with advanced automation, real-time data insights, and
            collaborative tools, all working together to ensure consistency and
            accuracy at every step. In essence, our solutions become a vital
            partner in your quest for precision, improving both the quality of
            your diagnostics and the efficiency of your operations.
          </p>
        </div>
        <div className="solutions_section_2_2">
          <img src={imageUrls[4]} />
        </div>
      </div>
      <div className="solutions_section_3">
        <p className="solutions_section_3_heading font-Nunito-700">
          Meet our Two revolutionary platforms
        </p>
        <div className="solutions_section_3_1">
          <div className="solutions_section_3_1_1">
            <div className="solutions_section_3_1_1_1 font-Nunito-700">
              <p>SciLisa:</p>
              <p>
                The New Era of <br />
                Digital ELISA Technology
              </p>
            </div>
            <div className="solutions_section_3_1_1_2 font-Nunito-400">
              <p>
                Step into the future of immunoassays with SciLisa, our advanced
                digital ELISA platform. Combining the precision of traditional
                ELISA with the power of smart digital processing, SciLisa
                delivers faster, more accurate results—all while simplifying
                your workflow. Imagine this your lab is running multiple tests
                simultaneously. SciLisa seamlessly integrates all data into a
                user-friendly interface, automatically syncing with the cloud
                portal for remote access and analysis. Results are instant,
                accurate, and ready to be shared across the platform. Efficiency
                redefined.
              </p>
              <p className="font-Nunito-700">The SciLisa Ecosystem includes:</p>
              <ul>
                <li>
                  Analyzer Device: A compact, high-precision digital ELISA
                  analyzer with intuitive design.
                </li>
                <li>
                  Smart Cloud Portal: Access your data from anywhere,
                  collaborate with your team remotely, and utilise advanced
                  analytics tools to optimize decision-making.
                </li>
                <li>
                  Accessories & Services: Consumables, maintenance services, and
                  customer support to keep your lab at peak performance.
                </li>
              </ul>
            </div>
            <div className="solutions_section_3_1_1_3 font-Nunito-600">
              <button>Learn More</button>
            </div>
          </div>
          <div className="solutions_section_3_1_1"
          style={{marginTop:"2rem"}}
          >
            <div className="solutions_section_3_1_1_1 font-Nunito-700">
              <p>MyCount Xpress:</p>
              <p>
                Revolutionizing CBC <br />
                Analysis
              </p>
            </div>
            <div className="solutions_section_3_1_1_2 font-Nunito-400">
              <p>
                MyCount Xpress revolutionizes complete blood count (CBC)
                analysis, making it an essential tool for doctors and clinics.
                This compact powerhouse delivers 5-part differential
                capabilities with lightning-fast results in just 10-12
                minutes—perfect for today’s high-demand healthcare environment.
                Imagine your practice efficiently managing high patient volumes
                with ease. MyCount Xpress streamlines sample processing,
                providing detailed differential counts with minimal input.
                Results are automatically uploaded to our Smart Cloud Portal for
                convenient remote review and advanced analytics, enhancing your
                prognosis workflow and patient care.
              </p>
              <p className="font-Nunito-700">
                The MyCount Xpress Ecosystem Includes:
              </p>
              <ul>
                <li>
                  Analyzer Device: A cutting-edge 5-part differential CBC
                  analyzer designed for exceptional accuracy, speed, and
                  user-friendliness.
                </li>
                <li>
                  Smart Cloud Portal: An integrated online platform enabling
                  seamless data management, analysis, and reporting for your
                  clinical needs.
                </li>
                <li>
                  Accessories & Services: Comprehensive support, including
                  consumables, regular calibrations, and expert assistance,
                  ensuring your analyzer operates at peak performance.
                </li>
              </ul>
            </div>
            <div className="solutions_section_3_1_1_3 font-Nunito-600">
              <button>Learn More</button>
            </div>
          </div>
        </div>
      </div>

      <div className="solutions_section_4">
        <div className="solutions_section_4_1">
        <p className="font-Montserrat-700">Ready to transform your clinic? </p>
        <p className="font-Nunito-300">The future of patient diagnostics is here with SciLisa and MyCount Xpress. Join us on this journey towards smarter, faster, and more precise diagnostics, and discover how our comprehensive platform solutions can elevate your clinic’s performance.</p>
        <button 
                onClick={()=>navigate("/contact")}
        
        >Contact Us</button>
        </div>
       
      </div>
      <div className="solutions_section_5">
        <div className="solutions_section_5_1">
            <span className="font-Montserrat-700">We have more such solutions on</span>
            <img src={imageUrls[5]}/>
            <span className="visit_inc_btn">Visit Sciverse Inc.</span>
        </div>
      </div>
    </div>
  );
};
