import React from 'react';
import "../../assets/styles/loader.css";

const Loader = ({ shape = 'circle' }) => {
  return (
    <div className={`loader ${shape === 'triangle' ? 'triangle' : ''}`}>
      {shape === 'circle' && (
        <svg viewBox="0 0 80 80">
          <circle r="32" cy="40" cx="40" id="circle-loader"></circle>
        </svg>
      )}
      {shape === 'triangle' && (
        <svg viewBox="0 0 86 80">
          <polygon points="43 8 79 72 7 72"></polygon>
        </svg>
      )}
      {shape === 'rect' && (
        <svg viewBox="0 0 80 80">
          <rect height="64" width="64" y="8" x="8"></rect>
        </svg>
      )}
    </div>
  );
};

export default Loader;

