import React from "react";
import { useNavigate } from "react-router-dom";
import {getDynamicImageUrl} from "../components/specific/imageBlob";

import "../assets/styles/services.css";


export const Services = () => {
  const navigate = useNavigate();
  const themeImg = getDynamicImageUrl("services/theme_img.svg");
  const aiAndSoftwareIcon = getDynamicImageUrl("services/aiAndSoftwareIcon.svg");
  const biosensorsAndMicrofluidicsIcon = getDynamicImageUrl("services/biosensorsAndMicrofluidicsIcon.svg");
  const designIcon = getDynamicImageUrl("services/designIcon.svg");
  const productEngineeringIcon = getDynamicImageUrl("services/productEngineeringIcon.svg");
  const horizontalDesignImg = getDynamicImageUrl("services/horizontalDesignImg.svg");
  const digitalPathologyIcon = getDynamicImageUrl("services/digitalPathologyIcon.svg");
  const publicHealthIcon = getDynamicImageUrl("services/publicHealthIcon.svg");
  const chronicCareIcon = getDynamicImageUrl("services/chronicCareIcon.svg");
  const bioDefenceIcon = getDynamicImageUrl("services/biodefenceIcon.svg");





  return (
    <div className="services_parent_container">
      <div className="services_section_1">
        <div className="services_section_1_1">
          <div className="services_section_1_1_1">
            <span className="font-Montserrat-400">We</span>
            <br />
            <span className="font-Montserrat-700">Empower,</span>
            {/* <br /> */}
            <span className="font-Montserrat-700">Transform,</span>
            {/* <br /> */}
            <span className="font-Montserrat-700">Innovate...</span>
          </div>
          <p className="services_heading font-Montserrat-700">Our Services and Capabilities:</p>
          <div className="services_section_1_1_2">
            <div className="services_section_1_1_2_1">
              <div className="services_section_1_1_2_1_1">
                <img src={aiAndSoftwareIcon} alt="" />
                <h2 className="font-Nunito-700">AI and Software</h2>
                <p className="font-Nunito-400">
                  We develop next-generation medical technologies with a focus
                  on innovation and precision. Our team collaborates on refining
                  concepts, feasibility studies, and prototyping, creating
                  impactful healthcare products through agile development.
                </p>
                <button className="font-Nunito-700"
                onClick={()=>navigate("/aisoftware")}
                >Know more</button>
              </div>
              {/* <div className="services_section_1_1_2_1_2"></div> */}
              <div
                className="services_section_1_1_2_1_1"
                style={{ border: "none", marginLeft: "5vw" }}
              >
                <img src={biosensorsAndMicrofluidicsIcon} alt="" />
                <h2 className="font-Nunito-700">Biosensors & Microfluidics</h2>
                <p className="font-Nunito-400">
                  At Sciverse, our dedicated team explores cutting-edge medical
                  technologies to find innovative solutions for pressing
                  healthcare challenges. We aim to advance medical diagnostics,
                  treatments, and patient care through rigorous research and
                  analysis.
                </p>
                <button className="font-Nunito-700"
                style={{cursor:"not-allowed"}}
                >Know more</button>
              </div>
            </div>
            <div className="services_section_1_1_2_1">
              <div className="services_section_1_1_2_1_1">
                <img src={designIcon} alt="" />
                <h2 className="font-Nunito-700">Design</h2>
                <p className="font-Nunito-400">
                  Leveraging our expertise in human-centered design and medical
                  engineering, we create sophisticated, user-friendly medical
                  devices. We ensure seamless technology integration, delivering
                  intuitive and efficient solutions tailored to healthcare
                  individuals.
                </p>
                <button className="font-Nunito-700"
                style={{cursor:"not-allowed", zIndex:"0"}}
                
                >Know more</button>
              </div>
              {/* <div className="services_section_1_1_2_1_2"></div> */}
              <div
                className="services_section_1_1_2_1_1"
                style={{ border: "none", marginLeft: "5vw" }}
              >
                <img src={productEngineeringIcon} alt="" />
                <h2 className="font-Nunito-700">Product Engineering</h2>
                <p className="font-Nunito-400">
                  We ensure every product meets regulatory standards and exceeds
                  expectations for safety, reliability, and performance. From
                  assembly to packaging, we deliver superior medical solutions
                  to the healthcare providers worldwide.
                </p>
                <button className="font-Nunito-700"
                style={{cursor:"not-allowed"}}
                
                >Know more</button>
              </div>
            </div>
            {/* <div className="services_section_1_1_2_2">
            <div className="services_section_1_1_2_2_1"></div>
            <div className="services_section_1_1_2_2_2"></div>


            </div> */}
          </div>
        </div>
        <div className="services_section_1_2">
          <img src={themeImg} alt="" loading="lazy" />
        </div>
      </div>
      <div className="services_section_2">
        <img src={horizontalDesignImg} alt="" />
      </div>
      <div className="services_section_3">
        <div className="services_section_3_1">
          <p className="font-Montserrat-700">We provide services that enhance multiple sectors of Healthcare</p>
          <div className="services_section_3_1_1">
            <div className="services_section_3_1_1_1">
              <img src={digitalPathologyIcon} alt="" />

              <span className="font-Montserrat-700">Digital Pathology</span>
              <p className="font-Nunito-400">
                Incorporating advanced imaging technology and artificial
                intelligence algorithms, we develop digital pathology products
                that revolutionize diagnostic workflows. Our platforms enable
                seamless digitization of blood samples, facilitating automated
                analysis, collaboration, and archival. Through high-resolution
                imaging and sophisticated analytics, pathologists can make rapid
                and accurate assessments, enhancing diagnostic precision and
                efficiency.
              </p>
            </div>
            <div className="services_section_3_1_1_1">
              <img src={publicHealthIcon} alt="" />

              <span className="font-Montserrat-700">Public Health</span>
              <p className="font-Nunito-400">
              We engineer sophisticated patient monitoring systems tailored to healthcare facilities' needs. Our products feature advanced sensors and AI-driven analytics for real-time health data monitoring. With intuitive user interfaces and remote accessibility, caregivers can efficiently track patients' vital signs and intervene promptly when necessary, optimizing patient outcomes and reducing healthcare costs.
              </p>
            </div>  <div className="services_section_3_1_1_1">
              <img src={chronicCareIcon} alt="" />

              <span className="font-Montserrat-700">Chronic Care</span>
              <p className="font-Nunito-400">
              Developing comprehensive B2B chronic disease management platforms, we integrate EMR/EHR systems with patient engagement tools and remote monitoring capabilities. Our solutions facilitate personalized care plans, medication adherence tracking, and lifestyle management support, empowering healthcare teams to deliver proactive and continuous care, ultimately improving patient outcomes and reducing healthcare costs.            
               </p>
            </div>
            <div className="services_section_3_1_1_1">
              <img src={bioDefenceIcon} alt="" />
 
              <span className="font-Montserrat-700">Biodefence</span>
              <p className="font-Nunito-400">
              Within this vital sector, Sciverse pioneers innovative technologies and strategies aimed at detecting, preventing, and mitigating biological hazards like pathogens, bioterrorism, and pandemics. Through relentless research, Sciverse develops advanced diagnostic tools, breakthrough vaccines, and stringent containment protocols. By harnessing the latest advancements in science and technology, we are committed to fortifying national and global health security, ensuring swift and decisive responses to any biological threat that arises.            
               </p>
            </div>
          </div>
          <button
          className="font-Nunito-700"
          // onClick={()=>navigate("/collaborate")}
          style={{cursor:"not-allowed"}}
          >Collaborate</button>
        </div>
      </div>
    </div>
  );
};
