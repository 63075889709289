import React, {useState,useEffect} from 'react'



export const MultiColorRow = ({ words, colors, isHovered }) => {

    const [animationRestarted, setAnimationRestarted] = useState(false);
    const rowStyle = {
       
      
        fontSize:"24px",
        fontWeight:"400",
        
        lineHeight:"29px",
        overflowX:"hidden",
        whiteSpace: 'nowrap',
        
        animation: isHovered || animationRestarted ? 'none' : 'slide 110s linear infinite',
      };
      useEffect(() => {
        let timer;
        if (!isHovered && !animationRestarted) {
          timer = setTimeout(() => {
            setAnimationRestarted(true);
            setTimeout(() => {
              setAnimationRestarted(false); // Reset animationRestarted after 40 seconds
            }, 100); // Small delay before resetting to avoid immediate restart
          }, 40000); // 40 seconds in milliseconds
        }
        return () => clearTimeout(timer);
      }, [isHovered, animationRestarted]);

     
  return (
    <>
    <div style={rowStyle}>
      {words.map((word, index) => (
        <span key={index} style={{ fontWeight:"900",marginRight:"2vw",color: colors[index % colors.length] }}>
          {word}{' '}
        </span>
      ))}
    </div>
    </>
  )
}
 