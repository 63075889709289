// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.work_parent_container {
    width: 100vw;
    min-width: 1400px;
    min-height: 500px;
    height: auto;
    /* background-color: #e45151; */
}

.our_work_section {
    width: 95%;
    min-width: 1100px;
    height: auto;
    min-height: 500px;
    /* background-color: #7da891; */
    margin-left: 5vw;
    display: flex;
}

.our_work_section_1 {
    width: 80%;
    height: auto;
}

.our_work_section_2 {
    width: 20%;
    height: auto;
}

.our_work_section_1 p:nth-child(1){
    font-size: 75px;
    font-weight: 700;
    color:#155C8C ;
}

.our_work_section_1 p:nth-child(2) {
    font-size: 32px;
    color: #3A3A3A;
    font-weight: 400;
    line-height: 43.65px;
}

.our_work_section_1 button {
    padding: 20px 30px 20px 30px;
    color: white;
    background-color: #155C8C;
    border-radius: 11px;
    border: none;
    font-size: 24px;
    font-weight: 700;
}

.our_work_section_2 img {
    margin-left: -40px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/ourWork.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;IACjB,YAAY;IACZ,+BAA+B;AACnC;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,YAAY;IACZ,iBAAiB;IACjB,+BAA+B;IAC/B,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,4BAA4B;IAC5B,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".work_parent_container {\n    width: 100vw;\n    min-width: 1400px;\n    min-height: 500px;\n    height: auto;\n    /* background-color: #e45151; */\n}\n\n.our_work_section {\n    width: 95%;\n    min-width: 1100px;\n    height: auto;\n    min-height: 500px;\n    /* background-color: #7da891; */\n    margin-left: 5vw;\n    display: flex;\n}\n\n.our_work_section_1 {\n    width: 80%;\n    height: auto;\n}\n\n.our_work_section_2 {\n    width: 20%;\n    height: auto;\n}\n\n.our_work_section_1 p:nth-child(1){\n    font-size: 75px;\n    font-weight: 700;\n    color:#155C8C ;\n}\n\n.our_work_section_1 p:nth-child(2) {\n    font-size: 32px;\n    color: #3A3A3A;\n    font-weight: 400;\n    line-height: 43.65px;\n}\n\n.our_work_section_1 button {\n    padding: 20px 30px 20px 30px;\n    color: white;\n    background-color: #155C8C;\n    border-radius: 11px;\n    border: none;\n    font-size: 24px;\n    font-weight: 700;\n}\n\n.our_work_section_2 img {\n    margin-left: -40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
