import React, { useState, useRef, useEffect } from "react";
import emailjs from "emailjs-com";
import "../assets/styles/letsConnectStyle.css";
import {getDynamicImageUrl} from "../components/specific/imageBlob";

const LetsConnect = () => {
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    message: "",
  });
  const [showSubmitMessage, setShowSubmitMessage] = useState(false);
  const timer_icon = getDynamicImageUrl("LetsConnectAsset/time_icon.svg");
  const mail_icon = getDynamicImageUrl("LetsConnectAsset/mail_icon.svg");
  const background_svg = getDynamicImageUrl("LetsConnectAsset/illustrator_main.svg");
  const background_svg_2 = getDynamicImageUrl("LetsConnectAsset/theme_img.svg");
  const background_vector = getDynamicImageUrl("LetsConnectAsset/illustrator.svg");

  useEffect(() => {
    setShowSubmitMessage(false);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = formRef.current;
    const formData = new FormData(form);
    console.log(formData);
    emailjs
      .sendForm(
        "service_blsw6qo",
        "template_nx2jxs9",
        form,
        "iLlUHLgYIXKnUB1i4"
      )
      .then((result) => {
        console.log("Email sent successfully:", result.text);
        form.reset(); // Optionally reset the form after submission
        setFormData({
          fullName: "",
          email: "",
          phone: "",
          message: "",
        });
        setShowSubmitMessage(true);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="lets_connect_container">
        {/* text info */}

        <label className="lets_connect_header_text font-Montserrat-700">
          Let’s Connect.
        </label>
        <div className="lets_connect_info_container">
          <label className="p-inline-2 font-Nunito-700">
            Thank you for stopping by.{" "}
          </label>
          <p className="p-inline-0_5 font-Nunito-400">
            We simply would love to know what you think.
          </p>
          <p className="p-inline-0_5 font-Nunito-400">
            So, please don't be shy to reach out with feedback, suggestions, a
            new idea to work on, or just a simple 'Hi'.
          </p>
          <p className="p-inline-0_5 font-Nunito-400">
            Please fill in the form below or find us on social media, or maybe
            get in touch via email...
          </p>
        </div>
        {/* form */}
        <form className="connect_us_form" onSubmit={handleSubmit} ref={formRef}>
          <img
            src={background_svg}
            alt="background_svg"
            className="background_svg_1"
          />

          <span>
            <label className="font-Nunito-400">What is your name?</label>
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </span>
          <span>
            <label className="font-Nunito-400">
              Where can we write you e-mail at?
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </span>
          <span>
            <label className="font-Nunito-400">
              What phone number can we reach you on?
            </label>
            <input
              type="number"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </span>
          <span>
            <label className="font-Nunito-400">
              We always read... Write us a message.
            </label>
            <textarea
              type="text"
              rows={7}
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </span>
          <button className="lets_connect_submit_btn font-Nunito-400 ">
            Send Message
          </button>
        </form>
        {showSubmitMessage && (
          <label className="submit_message">
            Thank you for getting in touch with us!
          </label>
        )}

        <div className="office_info_cls">
          <label className="office_time_info">
            <img src={timer_icon} alt="clock_icon" /> 09:30-18:30
          </label>
          <label className="office_mail_info">|</label>
          <label className="office_mail_info">
            <img src={mail_icon} alt="email_icon" />
            info@sciverse.co.in
          </label>
        </div>

        <img src={background_svg_2} alt="img" className="background_svg_2" />
        <img
          src={background_vector}
          alt="img"
          className="background_vector_cls"
        />
      </div>
    </>
  );
};

export default LetsConnect;
