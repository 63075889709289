import React from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/styles/footer.css";
import { getDynamicImageUrl } from "../specific/imageBlob";

export const Footer = ({onNavClick}) => {
  const navigate = useNavigate();
  const phone_icon = getDynamicImageUrl("footer/phone.svg");
  const linkedin_icon = getDynamicImageUrl("footer/linkedin.svg");
  const email_icon = getDynamicImageUrl("footer/email.svg");
  const instagram_icon = getDynamicImageUrl("footer/instagram.svg");
  const copyright_icon = getDynamicImageUrl("footer/copyright.svg");
  const footer_sciverse_icon = getDynamicImageUrl("header/sciverse_logo.svg");
  const location_icon = getDynamicImageUrl("footer/location.svg");

  const navigateLink = (link) => {
    window.open(link, "_blank");
  };

  const handleNavigation = (event) => {
    const link = event.currentTarget.dataset.link;
    if (link) {
      navigateLink(link);
    }
  };

  const emailAddress = "info@sciverse.co.in";
  const gmailWebURL = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(
    emailAddress
  )}`;

  return (
    <footer>
      <div className="footer_main_container">
        <div className="footer_section">
          <div className="footer_section_1">
            <section className="contact-section">
              <span className="get_label font-Nunito-700">Get In Touch</span>
              <div className="contact_info">
                <div className="contact_item">
                  <img src={phone_icon} alt="" />
                  <p className="font-Nunito-600">+91 8899667799</p>
                </div>
                <div className="contact_item">
                  <img src={location_icon} alt="" />
                  <div className="font-Nunito-600">
                    <p>
                      <strong className="font-Nunito-600">India</strong>
                    </p>
                    <div className="address_container font-Nunito-400">
                      <strong className="font-Nunito-600">Head Office: </strong>
                      307, Vasudha-V18, Balewadi High Street, Pune (MH) - 411045
                      <br />
                      <strong className="font-Nunito-600">
                        Sciverse Research Center:{" "}
                      </strong>{" "}
                      1st Floor-Govind Plaza, Swati Park, Baner, Pune (MH) -
                      411045
                      <br />
                      <strong className="font-Nunito-600">
                        Sciverse Innovation Center:{" "}
                      </strong>
                      Sciverse Innovation Center: SIC, Amar Paradigm, Baner,
                      Pune (MH) - 411045
                    </div>
                  </div>
                </div>
                <div className="contact_item">
                  <img src={location_icon} alt="" />
                  <div>
                    <p>
                      <strong className="font-Nunito-700">USA</strong>
                    </p>
                    <div className="address_container_2 font-Nunito-400">
                      <strong className="font-Nunito-700">
                        Sciverse Inc. :{" "}
                      </strong>
                      1111b South Governors Avenue, STE 20615, Dover, DE 19904
                      USA
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="footer_section_2">
            <section className="aboutus_section">
              <p className="font-Nunito-700">Our Offerings</p>
              <ul>
                <li
                  className="font-Nunito-400"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/aisoftware");
                    onNavClick();
                  }}
                >
                  AI & Software
                </li>
                <li
                  className="font-Nunito-400"
                  style={{ cursor: "not-allowed" }}
                >
                  Product Design
                </li>
                <li
                  className="font-Nunito-400"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/aisoftware");
                    onNavClick();

                  }}
                >
                  Microfluidics & Biosensors
                </li>
                <li
                  className="font-Nunito-400"
                  style={{ cursor: "not-allowed" }}
                >
                  Product Design
                </li>
              </ul>
            </section>
          </div>
          <div className="footer_section_3">
            <section className="assistance_section">
              <p
                // onClick={() => navigate("/survey")}
                className="font-Nunito-700"
                style={{ width: "18rem" }}
              >
                Supporting Links
              </p>
              <ul>
                <li
                   onClick={() => {
                    onNavClick();
                    navigate("/ourteam");
                   }}
                  className="font-Nunito-400"
                  // style={{ cursor: "not-allowed" }}
                >
                  Our People
                </li>
                <li
                  className="font-Nunito-400"
                  style={{ cursor: "not-allowed" }}
                >
                  Careers
                </li>
                <li
                  onClick={() => {
                    navigate("/termsandcondition");
                    onNavClick();

                  }}
                  className="font-Nunito-400"
                >
                  Terms & Conditions
                </li>
                <li
                  onClick={() => {
                    navigate("/privacypolicy");
                    onNavClick();


                  }}
                  style={{ cursor: "pointer" }}
                  className="font-Nunito-400"
                >
                  Privacy Policy
                </li>
              </ul>
            </section>
          </div>
        </div>
        <hr className="horizontal_footer_line"></hr>
        <div className="footer_section_second">
          <div className="footer_section_second_1">
            <div className="footer_section_second_1_1">
              <img
                src={linkedin_icon}
                alt=""
                data-link="https://www.linkedin.com/company/sciverse/mycompany/"
                onClick={handleNavigation}
              />
              <a
                className="contact-icons gmail"
                href={gmailWebURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={email_icon} alt="" />
              </a>
              {/* <img src={email_icon} alt="" /> */}
              <img src={instagram_icon} alt="" />
            </div>

            <div
              style={{
                position: "relative",
                color: "rgba(58, 58, 58, 1)",
                fontSize: "22px",
              }}
              className="font-Nunito-400 "
            >
              Copyright{" "}
              <img
                style={{ position: "absolute", top: "1px" }}
                src={copyright_icon}
                alt=""
              />{" "}
              <span style={{ opacity: "0" }} className="font-Nunito-400">
                no
              </span>{" "}
              2024 Sciverse Solutions Private Limited
            </div>
          </div>
          <div className="footer_section_second_2">
            <img src={footer_sciverse_icon} alt="" />
          </div>
        </div>
      </div>
    </footer>
  );
};
