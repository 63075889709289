import React, {useState, useEffect, useCallback} from 'react';
import "../../assets/styles/blockingOverlay.css";
import {getDynamicImageUrl} from "../specific/imageBlob";


export const BlockingOverlay = () => {
    const [shouldBlock, setShouldBlock] = useState(false);
  const blockImg = getDynamicImageUrl("others/BlockingOverlay.svg");
  
    const throttle = (func, limit) => {
      let lastFunc;
      let lastRan;
      return function (...args) {
        const context = this;
        if (!lastRan) {
          func.apply(context, args);
          lastRan = Date.now();
        } else {
          clearTimeout(lastFunc);
          lastFunc = setTimeout(function () {
            if (Date.now() - lastRan >= limit) {
              func.apply(context, args);
              lastRan = Date.now();
            }
          }, limit - (Date.now() - lastRan));
        }
      };
    };
  
    const handleResize = useCallback(() => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const shouldBlock = (screenWidth < 1400 || screenHeight < 800) || (screenWidth > 1920 || screenHeight > 1080);

      setShouldBlock(shouldBlock);
    }, []);
  
    useEffect(() => {
    
      handleResize();
  
      // Throttle the resize event listener
      const throttledResize = throttle(handleResize, 200); 
      window.addEventListener('resize', throttledResize);
  
      return () => {
        window.removeEventListener('resize', throttledResize);
      };
    }, [handleResize]);
  
    return shouldBlock ? (
      <div className='main_container'>
        <div className='sub_contain'>
          <img src={blockImg} />
        </div>
      </div>
    ) : null;
  };
