import React, {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import "../assets/styles/survey.css";
import {getDynamicImageUrl} from "../components/specific/imageBlob";


export const Survey = () => {
  const location = useLocation();

  const[formData, setFormData] = useState({
    deviceMonitoring:[],
    deviceUsage:[],
    deviceAccuracy:[],
    patientMonitoring:[],
    importantFeature:[],
    recommendProduct:[],
    primaryInfo:[]
})

  const survey_img = getDynamicImageUrl("survey/survey_img.svg");
  const surveyVector = getDynamicImageUrl("survey/surveyVector.svg");
  const surveyThemeImg = getDynamicImageUrl("survey/survey_theme_img.svg");
  const timeIcon = getDynamicImageUrl("survey/time_icon.svg");
  const locationIcon = getDynamicImageUrl("survey/location_icon.svg");
  const EmailIcon = getDynamicImageUrl("survey/mail.svg");
  const PhoneIcon = getDynamicImageUrl("survey/call.svg");


useEffect(()=>{
  window.scrollTo(0,0);
},[location]);

const handleCheckboxChange = (e) => {
  const {name, value} = e.target;
  if(e.target.checked) {
    setFormData((prevData) => ({
      ...prevData,
      [name]: [...prevData[name], value],
    }));
  }
  else {
    setFormData((prevData) => ({
      ...prevData,
      [name]: prevData[name].filter((item) => item!== value),
    }));
  }
};

const handleSubmit = (e) => {
  e.preventDefault();
  console.log(formData);
  setFormData(
    { deviceMonitoring:[],
      deviceUsage:[],
      deviceAccuracy:[],
      patientMonitoring:[],
      importantFeature:[],
      recommendProduct:[],
      primaryInfo:[]
    }
  )
}

  return (
    <div className='survey_parent_container'>
      <div className='survey_parent_container_1'>
        <div className='survey_section_1'>
          <div className='survey_section_1_1'>
          <p> Take a Survey</p>
          <p>Share Your Insights: Help Us Improve Our Medical Devices</p>
          <p>By participating in this short survey, you’ll help us gather insights that will shape the development of our products and services. <br />
          Whether you’re a healthcare professional, a patient, or simply someone interested in medical technology, your feedback is crucial to our mission of enhancing health outcomes.</p>
          </div>
          <div className='survey_section_1_2'>
              <img src={survey_img} />
          </div>

         
  
        </div>
        <div className='survey_section_2'>
        <form className='survey_section_2'
        onSubmit={handleSubmit}
        >
          <div className='survey_section_2_1'> 
          
              <h3> How often do you use medical devices for monitoring your health?</h3>
              <label class="custom-checkbox">
                <input 
                type='checkbox'
                name='deviceMonitoring'
                value="Daily"
                onChange={handleCheckboxChange}
                checked={formData.deviceMonitoring.includes('Daily')}
                required
                />
                <span className="checkmark"></span>Daily
               </label><br/>
               <label  class="custom-checkbox">
                <input 
                type='checkbox'
                name='deviceMonitoring'
                value="Weekly"
                onChange={handleCheckboxChange}
                checked={formData.deviceMonitoring.includes('Weekly')}
                /><span className="checkmark"></span>Weekly
               </label><br/>
               <label  class="custom-checkbox">
                <input 
                type='checkbox'
                name='deviceMonitoring'
                value="Monthly"
                onChange={handleCheckboxChange}
                checked={formData.deviceMonitoring.includes('Monthly')}

                /><span className="checkmark"></span>Monthly
               </label><br/>
               <label  class="custom-checkbox">
                <input 
                type='checkbox'
                name='deviceMonitoring'
                value="Rarely"
                onChange={handleCheckboxChange}
                checked={formData.deviceMonitoring.includes('Rarely')}
                /><span className="checkmark"></span>Rarely
               </label><br/><label  class="custom-checkbox">
                <input 
                type='checkbox'
                name='deviceMonitoring'
                value="Never"
                onChange={handleCheckboxChange}
                checked={formData.deviceMonitoring.includes('Never')}
                /><span className="checkmark"></span>Never
               </label><br/><br/>
               <h3>Which type of medical device do you use most frequently?</h3>
               <label  class="custom-checkbox">
                <input 
                type='checkbox'
                name='deviceUsage'
                value="Blood pressure monitor"
                onChange={handleCheckboxChange}
                checked={formData.deviceUsage.includes('Blood pressure monitor')}

                /><span className="checkmark"></span>Blood pressure monitor
               </label><br/>
               <label  class="custom-checkbox">
                <input 
                type='checkbox'
                name='deviceUsage'
                value="Glucose meter"
                onChange={handleCheckboxChange}
                checked={formData.deviceUsage.includes('Glucose meter')}

                /><span className="checkmark"></span>Glucose meter
               </label><br/>        <label  class="custom-checkbox">
                <input 
                type='checkbox'
                name='deviceUsage'
                value="Heart rate monitor"
                onChange={handleCheckboxChange}
                checked={formData.deviceUsage.includes('Heart rate monitor')}
                

                /><span className="checkmark"></span>Heart rate monitor
               </label><br/>        <label  class="custom-checkbox">
                <input 
                type='checkbox'
                name='deviceUsage'
                value="Pulse oximeter"
                onChange={handleCheckboxChange}
                checked={formData.deviceUsage.includes('Pulse oximeter')}
                /><span className="checkmark"></span>Pulse oximeter
               </label><br/>        
               <label  class="custom-checkbox">
                <input 
                type='checkbox'
                name='deviceUsage'
                value="Other"
                onChange={handleCheckboxChange}
                checked={formData.deviceUsage.includes('Other')}
                /><span className="checkmark"></span>Other
                </label><br/><br/>
                <h3>How satisfied are you with the accuracy of your current medical devices?</h3>
                <label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='deviceAccuracy'
                  value="Very satisfied"
                  onChange={handleCheckboxChange}
                  checked={formData.deviceAccuracy.includes('Very satisfied')}
                  /><span className="checkmark"></span>Very satisfied
                </label><br/>
                <label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='deviceAccuracy'
                  value="Satisfied"
                   onChange={handleCheckboxChange}
                checked={formData.deviceAccuracy.includes('Satisfied')}
                  /><span className="checkmark"></span>Satisfied
                </label><br/><label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='deviceAccuracy'
                  value="Neutral"
                   onChange={handleCheckboxChange}
                checked={formData.deviceAccuracy.includes('Neutral')}
                  /><span className="checkmark"></span>Neutral
                </label><br/><label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='deviceAccuracy'
                  value="Dissatisfied"
                   onChange={handleCheckboxChange}
                checked={formData.deviceAccuracy.includes('Dissatisfied')}
                  /><span className="checkmark"></span>Dissatisfied
                </label><br/><label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='deviceAccuracy'
                  value="Very dissatisfied"
                   onChange={handleCheckboxChange}
                checked={formData.deviceAccuracy.includes('Very dissatisfied')}
                  /><span className="checkmark"></span>Very dissatisfied
                </label><br/><br/>
                <h3>Which type of patient monitoring are you most interested in?</h3>
                <label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='patientMonitoring'
                  value="Remote patient monitoring"
                   onChange={handleCheckboxChange}
                checked={formData.patientMonitoring.includes('Remote patient monitoring')}
                  /><span className="checkmark"></span>
                Remote patient monitoring
                </label><br/>
                <label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='patientMonitoring'
                  value="Ambulatory patient monitoring"
                   onChange={handleCheckboxChange}
                checked={formData.patientMonitoring.includes('Ambulatory patient monitoring')}
                  /><span className="checkmark"></span>
                Ambulatory patient monitoring
                </label><br/> <label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='patientMonitoring'
                  value="In-hospital patient monitoring"
                   onChange={handleCheckboxChange}
                checked={formData.patientMonitoring.includes('In-hospital patient monitoring')}
                  /><span className="checkmark"></span>
                In-hospital patient monitoring
                </label><br/> <label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='patientMonitoring'
                  value="At-home patient monitoring"
                   onChange={handleCheckboxChange}
                checked={formData.patientMonitoring.includes('At-home patient monitoring')}
                  /><span className="checkmark"></span>
               At-home patient monitoring
                </label><br/> <label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='patientMonitoring'
                  value="Others"
                   onChange={handleCheckboxChange}
                checked={formData.patientMonitoring.includes('Others')}
                  /><span className="checkmark"></span>
                Others
                </label><br/> <br />
                <button>Submit Your Answers</button>

                <div className='survey_address'>
                <div className='survey_address_1'>
              <span>
                <img src={locationIcon}/>307, V-18, Balewadi High Street, Pune- 411045
              </span><br/>
              <span>
                <img src={timeIcon} />09:30 - 18:30
              </span>
             </div>
             <div className='survey_address_2'>
            <span>
              <img  src={EmailIcon}/> info@sciverse.co.in
            </span><br/>
            <span>
              <img src={PhoneIcon}/>+91 8240401969
            </span>
          </div>
                </div>
           

       
          </div>
          <div className='survey_section_2_2'>
            <div className='survey_section_2_2_1'>
            <h3>What is the most important feature you look for in a medical device?</h3>
                <label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='importantFeature'
                  value="Accuracy"
                    onChange={handleCheckboxChange}
                checked={formData.importantFeature.includes('Accuracy')}
                  /><span className="checkmark"></span>Accuracy
                </label><br/>
                <label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='importantFeature'
                  value="Ease of Use"
                    onChange={handleCheckboxChange}
                checked={formData.importantFeature.includes('Ease of Use')}
                  /><span className="checkmark"></span>Ease of Use
                </label><br/><label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='importantFeature'
                  value="Portability"
                    onChange={handleCheckboxChange}
                checked={formData.importantFeature.includes('Portability')}
                  /><span className="checkmark"></span>Portability
                </label><br/><label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='importantFeature'
                  value="Price"
                    onChange={handleCheckboxChange}
                checked={formData.importantFeature.includes('Price')}
                  /><span className="checkmark"></span>Price
                </label><br/><label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='importantFeature'
                  value="Integration with other devices/apps"
                    onChange={handleCheckboxChange}
                checked={formData.importantFeature.includes('Integration with other devices/apps')}
                  /><span className="checkmark"></span>Integration with other devices/apps
                </label><br/><br/>
                <h3>How likely are you to recommend Sciverse Medtech products to others?</h3>
                <label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='recommendProduct'
                  value="Very Likely"
                    onChange={handleCheckboxChange}
                checked={formData.recommendProduct.includes('Very Likely')}
                  /><span className="checkmark"></span>Very Likely
                </label><br/>
                <label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='recommendProduct'
                  value="Likely"
                    onChange={handleCheckboxChange}
                checked={formData.recommendProduct.includes('Likely')}
                  /><span className="checkmark"></span>Likely
                </label><br/><label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='recommendProduct'
                  value="Neutral"
                    onChange={handleCheckboxChange}
                checked={formData.recommendProduct.includes('Neutral')}
                  /><span className="checkmark"></span>Neutral
                </label><br/><label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='recommendProduct'
                  value="Unlikely"
                    onChange={handleCheckboxChange}
                checked={formData.recommendProduct.includes('Unlikely')}
                  /><span className="checkmark"></span>Unlikely
                </label><br/><label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='recommendProduct'
                  value="Very Unlikely"
                    onChange={handleCheckboxChange}
                checked={formData.recommendProduct.includes('Very Unlikely')}Others
                  /><span className="checkmark"></span>Very Unlikely
                </label><br/><br/>
                <h3>What is your primary source of information about new medical devices?</h3>
                <label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='primaryInfo'
                  value="Healthcare professionals"
                    onChange={handleCheckboxChange}
                checked={formData.primaryInfo.includes('Healthcare professionals')}
                  /><span className="checkmark"></span>Healthcare professionals
                </label><br/>
                <label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='primaryInfo'
                  value="Online research"
                    onChange={handleCheckboxChange}
                checked={formData.primaryInfo.includes('Online research')}
                  /><span className="checkmark"></span>Online research
                </label><br/><label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='primaryInfo'
                  value="Social media"
                    onChange={handleCheckboxChange}
                checked={formData.primaryInfo.includes('Social media')}
                  /><span className="checkmark"></span>Social media
                </label><br/><label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='primaryInfo'
                  value="Friends and family"
                    onChange={handleCheckboxChange}
                checked={formData.primaryInfo.includes('Friends and family')}
                  /><span className="checkmark"></span>Friends and family
                </label><br/><label  class="custom-checkbox">
                  <input 
                  type='checkbox'
                  name='primaryInfo'
                  value="Other"
                    onChange={handleCheckboxChange}
                checked={formData.primaryInfo.includes('Other')}
                  /><span className="checkmark"></span>Other
                </label><br/><br/>
                
         
            </div>
          
            
             </div>
          <div className='survey_section_2_3'>
            <img src={surveyVector}/>
            <img src={surveyThemeImg}/>
             </div>
          </form>
        </div>
      </div>
    </div>
  )
}



