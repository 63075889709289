// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background:linear-gradient(190deg, #ce7da0 -3.26%, #9876ce 100%); */
  background: linear-gradient(to bottom, #dfeefc, #f2f4f6);

      font-size: 2.5vw;
    color: whitesmoke;
    z-index: 9999;
    /* display: flex;
    flex-direction: column; */
    /* display: grid;
    place-items: center; */
    overflow: hidden;
    overflow-y: hidden;
  }

  .sub_contain{
    width: 100vw;
    height: 40vh;
    margin-top: 5vh;
}
.sub_contain img{
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    }
    `, "",{"version":3,"sources":["webpack://./src/assets/styles/blockingOverlay.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,sEAAsE;EACxE,wDAAwD;;MAEpD,gBAAgB;IAClB,iBAAiB;IACjB,aAAa;IACb;6BACyB;IACzB;0BACsB;IACtB,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,eAAe;AACnB;AACA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb","sourcesContent":[".main_container {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    /* background:linear-gradient(190deg, #ce7da0 -3.26%, #9876ce 100%); */\n  background: linear-gradient(to bottom, #dfeefc, #f2f4f6);\n\n      font-size: 2.5vw;\n    color: whitesmoke;\n    z-index: 9999;\n    /* display: flex;\n    flex-direction: column; */\n    /* display: grid;\n    place-items: center; */\n    overflow: hidden;\n    overflow-y: hidden;\n  }\n\n  .sub_contain{\n    width: 100vw;\n    height: 40vh;\n    margin-top: 5vh;\n}\n.sub_contain img{\n    width: 100vw;\n    height: 100vh;\n    z-index: 9999;\n    }\n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
