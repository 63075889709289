import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../assets/styles/career.css";
import {getDynamicImageUrl} from "../components/specific/imageBlob";

export const Career = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");

  const careerThemeImg = getDynamicImageUrl("career/careerThemeImg.svg");
  const locIcon = getDynamicImageUrl("letsCollaborate/location_icon.svg");
  const timeIcon = getDynamicImageUrl("career/arrow_outward.svg");
  const applyArrow = getDynamicImageUrl("career/arrow_outward.svg");
  const searchIcon = getDynamicImageUrl("career/searchIcon.svg");

  useEffect(() => {
    // Scroll to top when the page is loaded
    window.scrollTo(0, 0);
  }, [location]);

  const handleSearch = () => {
    console.log('Searching for:', searchTerm); 
  };


  const jobPostings = [
    {
      department: "Administration",
      title: "Accounts & Admin Manager",
      description: "We are looking for a full-time, multitasking Admin and Accountant with at least 1 year + experience",
      location: "Balewadi High Street, Pune",
      type: "Full-time"
    },
    {
      department: "Human Resources",
      title: "HR Manager",
      description: "Join us as an HR manager and help in recruiting and onboarding talented people.",
      location: "Balewadi High Street, Pune",
      type: "Full-time"
    },
    {
      department: "Engineering",
      title: "Mechanical Design Engineer",
      description: "We are looking for a skilled and knowledgeable Mechanical Design Engineer.",
      location: "Balewadi High Street, Pune",
      type: "Full-time"
    },{
      department: "Administration",
      title: "Project Manager",
      description: "We are looking for an experienced and detail-oriented Project Manager.",
      location: "Balewadi High Street, Pune",
      type: "Full-time"
    },{
      department: "Engineering",
      title: "Software Production Engineer",
      description: "We are looking for a professional and dedicated Software Production Engineer.",
      location: "Balewadi High Street, Pune",
      type: "Full-time"
    },
    // Add more job postings as needed
  ];

  const handleApplyClick = (jobTitle) => {
    navigate("/application", {state: {title: jobTitle} });
  }

  return (
    <div className="career_parent_container">
      <div className="career_section_container">
        <div className="career_column_1">
          <div className="career_section_1">
            {/* <div className='career_column_1_1'></div> */}
            <p className="font-Nunito-700">Join our team of super- talented and fun individuals!</p>
            <p className="font-Nunito-600">
              Our philosophy is simple - At Sciverse, we believe in hiring
              committed and responsible individuals, empowering them to learn by
              doing, and unleashing their potential to drive transformative
              change in all fields they find interest in!
            </p>
          </div>
          <div className="career_section_2">
            <div className="career_section_2_1">
              <input
                type="text"
                placeholder="Search"
                style={{paddingInline:"3rem",backgroundImage: `url(${searchIcon})`}}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search_input"
              />
              <button onClick={handleSearch} className="search_button"
              style={{cursor:"pointer  !important"}}
              >
                Search
              </button>
            </div>
            {/* <div className="career_section_2_2">
                <div className="job_posting">
                    <div className="job_posting_1">
                        <span>Administration</span><br />
                        <span className="span_2">Accounts & Admin Manager</span><br />
                        <span className="span_3">We are looking for a full-time, multitasking Admin and Accountant with at least 1 year + experience</span><br />
                        <span className="address_span">
                            <img src={locIcon} />Balewadi High Street, Pune.
                        </span>
                        <span className="address_span address_span_2">
                            <img src={timeIcon}/>  Full-time

                        </span>
                    </div>
                    <div className="job_posting_2"
                    onClick={()=>navigate("/application")}
                    >
                            <span>Apply <img src={applyArrow} /> </span>
                            
                    </div>

                </div>

                <div className="job_posting">
                    <div className="job_posting_1">
                        <span>Administration</span><br />
                        <span className="span_2">Accounts & Admin Manager</span><br />
                        <span className="span_3">We are looking for a full-time, multitasking Admin and Accountant with at least 1 year + experience</span><br />
                        <span className="address_span">
                            <img src={locIcon} />Balewadi High Street, Pune.
                        </span>
                        <span className="address_span address_span_2">
                            <img src={timeIcon}/>  Full-time

                        </span>
                    </div>
                    <div className="job_posting_2">
                            <span>Apply <img src={applyArrow} /> </span>
                            
                    </div>

                </div> <div className="job_posting">
                    <div className="job_posting_1">
                        <span>Administration</span><br />
                        <span className="span_2">Accounts & Admin Manager</span><br />
                        <span className="span_3">We are looking for a full-time, multitasking Admin and Accountant with at least 1 year + experience</span><br />
                        <span className="address_span">
                            <img src={locIcon} />Balewadi High Street, Pune.
                        </span>
                        <span className="address_span address_span_2">
                            <img src={timeIcon}/>  Full-time

                        </span>
                    </div>
                    <div className="job_posting_2">
                            <span>Apply <img src={applyArrow} /> </span>
                            
                    </div>

                </div> <div className="job_posting">
                    <div className="job_posting_1">
                        <span>Administration</span><br />
                        <span className="span_2">Accounts & Admin Manager</span><br />
                        <span className="span_3">We are looking for a full-time, multitasking Admin and Accountant with at least 1 year + experience</span><br />
                        <span className="address_span">
                            <img src={locIcon} />Balewadi High Street, Pune.
                        </span>
                        <span className="address_span address_span_2">
                            <img src={timeIcon}/>  Full-time

                        </span>
                    </div>
                    <div className="job_posting_2">
                            <span>Apply <img src={applyArrow} /> </span>
                            
                    </div>

                </div> <div className="job_posting">
                    <div className="job_posting_1">
                        <span>Administration</span><br />
                        <span className="span_2">Accounts & Admin Manager</span><br />
                        <span className="span_3">We are looking for a full-time, multitasking Admin and Accountant with at least 1 year + experience</span><br />
                        <span className="address_span">
                            <img src={locIcon} />Balewadi High Street, Pune.
                        </span>
                        <span className="address_span address_span_2">
                            <img src={timeIcon}/>  Full-time

                        </span>
                    </div>
                    <div className="job_posting_2">
                            <span>Apply <img src={applyArrow} /> </span>
                            
                    </div>

                </div>
            </div> */}

<div className="career_section_2_2">
      {jobPostings.map((job, index) => (
        <div key={index} className="job_posting">
          <div className="job_posting_1">
            <span>{job.department}</span><br />
            <span className="span_2 font-Montserrat-700">{job.title}</span><br />
            <span className="span_3 font-Nunito-400">{job.description}</span><br />
            <span className="address_span">
              <img src={locIcon} alt="location" />
              {job.location}
            </span>
            <span className="address_span address_span_2">
              <img src={timeIcon} alt="time" /> {job.type}
            </span>
          </div>
          <div className="job_posting_2" onClick={() => handleApplyClick(job.title)}>
            <span>Apply <img src={applyArrow} alt="apply" /></span>
          </div>
        </div>
      ))}
    </div>


          </div>
        </div>
        <div className="career_column_2">
            <div className="career_column_2_1">
                <img src={careerThemeImg} />

            </div>
        </div>
      </div>
    </div>
  );
};
