import React from 'react'
import "../assets/styles/ourWork.css"
import {getDynamicImageUrl} from "../components/specific/imageBlob";

export const OurWork = () => {

  const themeImg = getDynamicImageUrl("others/ourWorkThemeImg.svg");

  return (
    <div className='work_parent_container'>
        <div className='our_work_section'>
            <div className='our_work_section_1'>
              <p className='font-Montserrat-700'>Company Portfolio</p>
              <p className='font-Nunito-400'>Discover Sciverse, where innovation meets excellence. Our portfolio showcases our cutting-edge solutions in MedTech, Scientific research, Product design, and Product development, driving transformative results for businesses worldwide.</p>
              <button className='font-Nunito-700' style={{cursor:"not-allowed"}}>Request Portfolio</button>
            </div>
            <div className='our_work_section_2'>
              <img src={themeImg} alt=''/>
            </div>

        </div>
    </div>
  )
}
