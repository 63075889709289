// getDynamicImageUrl.js
export function getDynamicImageUrl(dynamicPath) {
  const baseUrl = "https://sciversewebsiteassets.blob.core.windows.net/assets/";

  const sasToken =
    "sv=2023-01-03&ss=btqf&srt=sco&st=2024-10-23T04%3A57%3A20Z&se=2027-01-24T04%3A57%3A00Z&sp=rl&sig=l3yU3O0cRxnCe211mWPruGpTK94mYWc2sdDYfEpFhJg%3D";

  const fullUrl = `${baseUrl}${dynamicPath}?${sasToken}`;

  return fullUrl;
}
