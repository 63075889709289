import './App.css';
import { BlockingOverlay } from './components/specific/BlockingOverlay';
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from './routes/AppRoutes';
function App() {
  return (
    <Router>
      <AppRoutes />
      {/* <BlockingOverlay /> */}
    </Router>
  
  );
}

export default App;
