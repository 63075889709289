import React from "react";
import {getDynamicImageUrl} from "../components/specific/imageBlob";
import { useNavigate } from "react-router-dom";
import "../assets/styles/aiAndSoftware.css";

export const AiAndSoftware = () => {
  const navigate = useNavigate();
  const rightImg = getDynamicImageUrl("aiDevExp/themeIconAIandSoftware.svg");
  const img1 = getDynamicImageUrl("aiDevExp/image1.svg");
  const img2 = getDynamicImageUrl("aiDevExp/image2.svg");
  const img3 = getDynamicImageUrl("aiDevExp/image3.svg");
  const img4 = getDynamicImageUrl("aiDevExp/image4.svg");
  const img5 = getDynamicImageUrl("aiDevExp/image5.svg");
  const img6 = getDynamicImageUrl("aiDevExp/image6.svg");
  const horizontalImg = getDynamicImageUrl("aiDevExp/horizontalImg.svg");
  const img7 = getDynamicImageUrl("aiDevExp/image7.svg");
  const img8 = getDynamicImageUrl("aiDevExp/image8.svg");
  const img9 = getDynamicImageUrl("aiDevExp/image9.svg");
  const img10 = getDynamicImageUrl("aiDevExp/image10.svg");


  const handleScrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };
  return (
    <div className="ai_parent_container">
      <div className="ai_section_1">
        <div className="ai_section_1_1">
          <div className="ai_section_1_1_1">
            <div className="ai_section_1_1_1_1 font-Montserrat-700">
              We harness the power of AI and Software to drive innovation in
              MedTech
            </div>
            <div className="ai_section_1_1_1_2 font-Nunito-400">
              At Sciverse, we leverage AI and advanced software to revolutionize
              MedTech by enhancing diagnostic accuracy and operational
              efficiency. Our AI-driven algorithms analyze complex medical data,
              enabling precise and timely insights. We integrate these
              innovations into intuitive software solutions that streamline
              medical processes and improve patient outcomes. By continually
              evolving our technological capabilities, we drive significant
              advancements in healthcare solutions.
            </div>
            <div className="ai_section_1_1_1_3">
              <button
                className="font-Nunito-600"
                // onClick={()=>navigate("/contact")}
                // style={{cursor:"not-allowed"}}
                // onClick={handleScrollToBottom}
                onClick={()=>navigate("/contact")}

              >
                Contact Us
              </button>
            </div>
          </div>
          <div className="ai_section_1_1_2">
            <img src={rightImg} loading="lazy" alt="" />
          </div>
        </div>
      </div>
      <div className="ai_section_2">
        <div className="ai_section_2_1 ">
          <h1 className="font-Nunito-700">
            Our Artificial Intelligence Development Expertise:
          </h1>
          <div className="ai_section_2_1_1">
            <div className="ai_section_2_1_1_1" style={{ marginTop: "50px" }}>
              <div
                className="ai_section_2_1_1_1_1"
                style={{ borderRight: "1px solid #3A3A3A" }}
              >
                <div className="ai_section_2_1_1_1_1_1">
                  <img src={img1} alt="" />
                </div>
                <div className="ai_section_2_1_1_1_1_2">
                  <div className="icon_desc">
                    <h2 className="font-Montserrat-700">
                      Advanced Medical Imaging Solutions
                    </h2>
                    <ul>
                      <li className="font-Nunito-400 ">
                        Deep Learning Segmentation
                      </li>
                      <li className="font-Nunito-400 ">Computer Vision</li>
                      <li className="font-Nunito-400 ">Image Registration</li>
                      <li className="font-Nunito-400 ">Automated Pathology</li>
                      <li className="font-Nunito-400 ">
                        Real-time Video Analysis
                      </li>
                      <li className="font-Nunito-400 ">3D Image Analysis</li>
                      <li className="font-Nunito-400 ">
                        Dynamic Imaging Analysis
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="ai_section_2_1_1_1_1">
                <div className="ai_section_2_1_1_1_1_1">
                  <img src={img2} alt="" />
                </div>
                <div className="ai_section_2_1_1_1_1_2">
                  <div className="icon_desc">
                    <h2 className="font-Montserrat-700">
                      Patient Centric AI Solutions
                    </h2>
                    <ul>
                      <li className="font-Nunito-400 ">Wearable Monitoring</li>
                      <li className="font-Nunito-400 ">Remote Analytics</li>
                      <li className="font-Nunito-400 ">Remote Monitoring</li>
                      <li className="font-Nunito-400 ">
                        Quantitative Biomarkers
                      </li>
                      <li className="font-Nunito-400 ">
                        Non-invasive Biomarkers
                      </li>
                      <li className="font-Nunito-400 ">Digital Twins</li>
                      <li className="font-Nunito-400 ">Multi-modal Modeling</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* 2nd Row  */}
            <div className="ai_section_2_1_1_1">
              <div
                className="ai_section_2_1_1_1_1"
                style={{ borderRight: "1px solid #3A3A3A" }}
              >
                <div className="ai_section_2_1_1_1_1_1">
                  <img style={{width:"80%", height:"50%"}} src={img3} alt="" />
                </div>
                <div className="ai_section_2_1_1_1_1_2">
                  <div className="icon_desc">
                    <h2 className="font-Montserrat-700">
                      Predictive Analysis & Anomaly Detection
                    </h2>
                    <ul>
                      <li className="font-Nunito-400 ">
                        Predictive Analytics in Healthcare
                      </li>
                      <li className="font-Nunito-400 ">
                        Automated Anomaly Detection
                      </li>
                      <li className="font-Nunito-400 ">
                        Predictive Modeling for Disease
                      </li>
                      <li className="font-Nunito-400 ">Progression</li>
                      <li className="font-Nunito-400 ">
                        Quality Control in Medical Imaging
                      </li>
                      <li className="font-Nunito-400 ">
                        Clinical Validation of AI Models
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="ai_section_2_1_1_1_1">
                <div className="ai_section_2_1_1_1_1_1">
                  <img src={img4} alt="" />
                </div>
                <div className="ai_section_2_1_1_1_1_2">
                  <div className="icon_desc"
                  style={{marginLeft:"3rem"}}
                  >
                    <h2 className="font-Montserrat-700">
                      Pathology and Clinical AI Applications
                    </h2>
                    <ul>
                      <li className="font-Nunito-400">Automated Reporting</li>
                      <li className="font-Nunito-400">Digital Pathology</li>
                      <li className="font-Nunito-400">
                        Clinical Knowledge Graphs
                      </li>
                      <li className="font-Nunito-400">
                        Population Health Management
                      </li>
                      <li className="font-Nunito-400">Remote Sensing</li>
                      <li className="font-Nunito-400">Behavioral Analytics</li>
                    </ul>
                  </div>
                </div>
                ``
              </div>
            </div>

            {/* 3rd Row */}

            <div className="ai_section_2_1_1_1">
              <div
                className="ai_section_2_1_1_1_1"
                style={{ borderRight: "1px solid #3A3A3A" }}
              >
                <div className="ai_section_2_1_1_1_1_1">
                  <img src={img5} alt="" />
                </div>
                <div className="ai_section_2_1_1_1_1_2">
                  <div className="icon_desc">
                    <h2 className="font-Montserrat-700">
                      Explainable and Interpretable AI Models
                    </h2>
                    <ul>
                      <li className="font-Nunito-400">Explainable Imaging A</li>
                      <li className="font-Nunito-400">Radiology Models</li>
                      <li className="font-Nunito-400">Attention Mechanisms</li>
                      <li className="font-Nunito-400">Bayesian Learning</li>
                      <li className="font-Nunito-400">Capsule Networks</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="ai_section_2_1_1_1_1">
                <div className="ai_section_2_1_1_1_1_1">
                  <img src={img6} alt="" />
                </div>
                <div className="ai_section_2_1_1_1_1_2">
                  <div className="icon_desc">
                    <h2 className="font-Montserrat-700">
                      Cutting Edge AI Research
                    </h2>
                    <ul>
                      <li className="font-Nunito-400">GANs</li>
                      <li className="font-Nunito-400">Edge AI</li>
                      <li className="font-Nunito-400">Robotics</li>
                      <li className="font-Nunito-400">Meta-learning</li>
                      <li className="font-Nunito-400">Adaptive AI Solutions</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="ai_section_2_1_1_2">
              <div className="ai_section_2_1_1_2_1"></div>
              <div className="ai_section_2_1_1_2_2"></div>
            </div>
            <div className="ai_section_2_1_1_3">
              <div className="ai_section_2_1_1_3_1"></div>
              <div className="ai_section_2_1_1_3_2"></div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="ai_section_3">
        <div className="ai_section_3_1">
          <img src={horizontalImg} alt="" />
        </div>
      </div>

      <div className="ai_section_4">
        <div className="ai_section_4_1">
          <h1
            style={{ marginTop: "50px", color: "#155C8C", marginLeft: "0vw" }}
            className="font-Nunito-700"
          >
            Our Software Development Expertise:
          </h1>
          <div className="ai_section_2_1_1_1" style={{ marginTop: "50px", width:"110%" }}>
            <div
              className="ai_section_2_1_1_1_1"
              style={{ borderRight: "1px solid #3A3A3A",width:"45%" }}
            >
              <div className="ai_section_2_1_1_1_1_1">
                <img src={img7} alt="" />
              </div>
              <div className="ai_section_2_1_1_1_1_2">
                <div className="icon_desc">
                  <h2 className="font-Montserrat-700">
                    Medical Device based Software Solutions
                  </h2>
                  <ul>
                    <li className="font-Nunito-400 ">
                      Specialized in Workflow SiMD and SaMD Solutions
                    </li>
                    <li className="font-Nunito-400 ">
                      Advanced Medical Image and Sensor Data Analysis Software
                    </li>
                    <li className="font-Nunito-400 ">
                      Cutting-edge Augmented Reality for Diagnosis and Treatment
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="ai_section_2_1_1_1_1">
              <div className="ai_section_2_1_1_1_1_1">
                <img src={img8} alt="" />
              </div>
              <div className="ai_section_2_1_1_1_1_2">
                <div className="icon_desc">
                  <h2 className="font-Montserrat-700">
                    Healthcare Information Systems
                  </h2>
                  <ul>
                    <li className="font-Nunito-400 ">
                      Reliable Healthcare Information Exchange (HIE) Systems
                    </li>
                    <li className="font-Nunito-400 ">
                      Efficient Electronic Health Records (EHR) and Electronic
                      Medical Records (EMR) Systems
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* 2nd Row  */}

          <div className="ai_section_2_1_1_1" style={{ marginTop: "3rem", marginBottom:"4rem", width:"110%" }}>
            <div
              className="ai_section_2_1_1_1_1"
              style={{ borderRight: "1px solid #3A3A3A", width:"45%" }}
            >
              <div className="ai_section_2_1_1_1_1_1">
                <img src={img9} alt="" />
              </div>
              <div className="ai_section_2_1_1_1_1_2">
                <div className="icon_desc">
                  <h2 className="font-Montserrat-700">
                    Web based Platform Applications
                  </h2>
                  <ul>
                    <li className="font-Nunito-400 ">Workflow Automation and Incident Management</li>
                    <li className="font-Nunito-400 ">Patient Flow, Medication, and Lab Test Management</li>
                    <li className="font-Nunito-400 ">
                      Health Tracking, Analytics, Scheduling, and Reminders
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="ai_section_2_1_1_1_1">
              <div className="ai_section_2_1_1_1_1_1">
                <img src={img10} alt="" />
              </div>
              <div className="ai_section_2_1_1_1_1_2">
                <div className="icon_desc">
                  <h2 className="font-Montserrat-700">
                    mHealth, IoT and Mobile Applications
                  </h2>
                  <ul>
                    <li className="font-Nunito-400 ">
                      Telehealth Integration and Remote Consultation
                    </li>
                    <li className="font-Nunito-400 ">
                      Digital Workspaces and Patient Portals
                    </li>
                    <li className="font-Nunito-400 ">
                      Remote Monitoring and Healthcare Apps
                    </li>
                    <li className="font-Nunito-400 ">
                      Bluetooth Apps for Patient Engagement and Health
                      Management
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
