// import React, { useState, useEffect } from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "../assets/styles/ourTeamStyle.css";
// import { getDynamicImageUrl } from "../components/specific/imageBlob";

// const Big_Vector = getDynamicImageUrl("OurTeamAsset/ourTeamVector.svg");
// const Small_Vector = getDynamicImageUrl("OurTeamAsset/ourTeamVector2.svg");

// const OurTeam = () => {
//   const [imageUrls, setImageUrls] = useState([]);
//   const imagesCount = 18; //When adding images to the blob storage, set the last images index here to view the result
//   useEffect(() => {
//     const imageNames = [];
//     for (let i = 1; i <= imagesCount; i++) {
//       imageNames.push(`Team_Members_Images/image${i}.svg`);
//     }
//     const urls = imageNames.map((name) => getDynamicImageUrl(name));
//     setImageUrls(urls);
//   }, []);

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 3,
//     arrows: true,
//   };
//   return (
//     <>
//       <div className="OurTeamPage_parent_container">
//         <img
//           src={Big_Vector}
//           alt="big_vector"
//           className="our_team_big_vector"
//           width={671}
//           height={671}
//         />
//         <img
//           src={Small_Vector}
//           alt="small_vector"
//           className="our_team_small_vector"
//         />

//         <div className="ourTeam_header_container">
//           <div className="ourTeam_header_tagline font-Montserrat-700">
//             We are a diverse bunch of smart, passionate peeps...
//           </div>
//           <div className="ourTeam_header_info font-Nunito-400">
//             Our team of experts possess a wealth of experience in product
//             development and are dedicated to leveraging the latest advancements
//             in microfluidics, artificial intelligence, and machine vision to
//             create disruptive solutions.
//           </div>
//           <div className="ourTeam_header_info font-Nunito-400">
//             Trust us, Its definitely fun getting to know each and every one of
//             us!!!
//           </div>
//         </div>

//         <div className="team_member_images_container">
//           <Slider {...settings}>
//             {imageUrls &&
//               imageUrls.map((url, index) => (
//                 <div key={index}>
//                   <img
//                     src={url}
//                     alt={`Image ${index + 1}`}
//                     style={{ width: "100%", height: "auto", userSelect:"none", border:"none",outline:"none", boxShadow:"none" }}
//                   />
//                 </div>
//               ))}
//           </Slider>
//         </div>
//       </div>
//     </>
//   );
// };

// export default OurTeam;


// import React, { useState, useEffect } from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "../assets/styles/ourTeamStyle.css";
// import { getDynamicImageUrl } from "../components/specific/imageBlob";

// const Big_Vector = getDynamicImageUrl("OurTeamAsset/ourTeamVector.svg");
// const Small_Vector = getDynamicImageUrl("OurTeamAsset/ourTeamVector2.svg");

// const OurTeam = () => {
//   const [imageUrls, setImageUrls] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const imagesCount = 18; // When adding images to the blob storage, set the last image index here to view the result

//   useEffect(() => {
//     const imageNames = [];
//     for (let i = 1; i <= imagesCount; i++) {
//       imageNames.push(`Team_Members_Images/image${i}.svg`);
//     }
//     const urls = imageNames.map((name) => getDynamicImageUrl(name));
//     setImageUrls(urls);

//     // Simulate loading state
//     const imageLoadPromises = urls.map((url) => {
//       return new Promise((resolve) => {
//         const img = new Image();
//         img.src = url;
//         img.onload = resolve;
//       });
//     });

//     Promise.all(imageLoadPromises).then(() => {
//       setIsLoading(false);
//     });
//   }, []);

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 3,
//     arrows: true,
//   };

//   return (
//     <>
//       <div className="OurTeamPage_parent_container">
//         <img
//           src={Big_Vector}
//           alt="big_vector"
//           className="our_team_big_vector"
//           width={671}
//           height={671}
//         />
//         <img
//           src={Small_Vector}
//           alt="small_vector"
//           className="our_team_small_vector"
//         />

//         <div className="ourTeam_header_container">
//           <div className="ourTeam_header_tagline font-Montserrat-700">
//             We are a diverse bunch of smart, passionate peeps...
//           </div>
//           <div className="ourTeam_header_info font-Nunito-400">
//             Our team of experts possess a wealth of experience in product
//             development and are dedicated to leveraging the latest advancements
//             in microfluidics, artificial intelligence, and machine vision to
//             create disruptive solutions.
//           </div>
//           <div className="ourTeam_header_info font-Nunito-400">
//             Trust us, Its definitely fun getting to know each and every one of
//             us!!!
//           </div>
//         </div>

//         <div className="team_member_images_container">
//           {isLoading ? (
//             <div className="loading-placeholder">
//               <p>Loading images...</p> {/* You can replace this with a spinner */}
//             </div>
//           ) : (
//             <Slider {...settings}>
//               {imageUrls &&
//                 imageUrls.map((url, index) => (
//                   <div key={index}>
//                     <img
//                       src={url}
//                       alt={`Image ${index + 1}`}
//                       style={{
//                         width: "100%",
//                         height: "auto",
//                         userSelect: "none",
//                         border: "none",
//                         outline: "none",
//                         boxShadow: "none",
//                       }}
//                     />
//                   </div>
//                 ))}
//             </Slider>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default OurTeam;



import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/styles/ourTeamStyle.css";
import { getDynamicImageUrl } from "../components/specific/imageBlob";
import Loader from "../components/common/Loader";

const Big_Vector = getDynamicImageUrl("OurTeamAsset/ourTeamVector.svg");
const Small_Vector = getDynamicImageUrl("OurTeamAsset/ourTeamVector2.svg");

const OurTeam = () => {
  const [initialImageUrls, setInitialImageUrls] = useState([]);
  const [remainingImageUrls, setRemainingImageUrls] = useState([]);
  const [isLoadingInitial, setIsLoadingInitial] = useState(true);
  const [isLoadingRemaining, setIsLoadingRemaining] = useState(true);

  const imagesCount = 18; // Set the total number of images here
  const initialLoadCount = 3; // Number of images to load initially

  useEffect(() => {
    const imageNames = [];
    for (let i = 1; i <= imagesCount; i++) {
      imageNames.push(`Team_Members_Images/image${i}.svg`);
    }
    const urls = imageNames.map((name) => getDynamicImageUrl(name));
    setInitialImageUrls(urls.slice(0, initialLoadCount));

    // Load initial images
    const initialImagePromises = urls.slice(0, initialLoadCount).map((url) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = url;
        img.onload = resolve;
      });
    });

    Promise.all(initialImagePromises).then(() => {
      setIsLoadingInitial(false);
      
      // Load remaining images asynchronously
      const remainingUrls = urls.slice(initialLoadCount);
      setRemainingImageUrls(remainingUrls);

      const remainingImagePromises = remainingUrls.map((url) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = url;
          img.onload = resolve;
        });
      });

      Promise.all(remainingImagePromises).then(() => {
        setIsLoadingRemaining(false);
      });
    });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
  };

  return (
    <>
      <div className="OurTeamPage_parent_container">
        <img
          src={Big_Vector}
          alt="big_vector"
          className="our_team_big_vector"
          width={671}
          height={671}
        />
        <img
          src={Small_Vector}
          alt="small_vector"
          className="our_team_small_vector"
        />

        <div className="ourTeam_header_container">
          <div className="ourTeam_header_tagline font-Montserrat-700">
            We are a diverse bunch of smart, passionate peeps...
          </div>
          <div className="ourTeam_header_info font-Nunito-400">
            Our team of experts possess a wealth of experience in product
            development and are dedicated to leveraging the latest advancements
            in microfluidics, artificial intelligence, and machine vision to
            create disruptive solutions.
          </div>
          <div className="ourTeam_header_info font-Nunito-400">
            Trust us, Its definitely fun getting to know each and every one of
            us!!!
          </div>
        </div>

        <div className="team_member_images_container">
          {isLoadingInitial ? (
            <div className="loader_container">
            {/* <Loader shape="circle" />
            <Loader shape="triangle" />
            <Loader shape="rect" /> */}
            {/* <p>Please wait</p> */}
            <div>
            <Loader shape="circle" />
            </div>
            <div>
            <Loader shape="triangle" />
            </div>
            <div>
              <Loader shape="rect" />
            </div><br/>
            <div
            style={{position:"absolute", bottom:"2rem"}}
            >Please Wait</div>

          </div>
          ) : (
            <Slider {...settings}>
              {initialImageUrls.map((url, index) => (
                <div key={index}>
                  <img
                    src={url}
                    alt={`Image ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "auto",
                      userSelect: "none",
                      border: "none",
                      outline: "none",
                      boxShadow: "none",
                    }}
                  />
                </div>
              ))}
              {!isLoadingRemaining &&
                remainingImageUrls.map((url, index) => (
                  <div key={initialLoadCount + index}>
                    <img
                      src={url}
                      alt={`Image ${initialLoadCount + index + 1}`}
                      style={{
                        width: "100%",
                        height: "auto",
                        userSelect: "none",
                        border: "none",
                        outline: "none",
                        boxShadow: "none",
                      }}
                    />
                  </div>
                ))}
            </Slider>
          )}
        </div>
      </div>
    </>
  );
};

export default OurTeam;
