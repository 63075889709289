import React, { useState, useRef, useEffect } from "react";
import emailjs from "emailjs-com";
import { useLocation } from "react-router-dom";
import "../assets/styles/careerApplication.css";
import { getDynamicImageUrl } from "../components/specific/imageBlob";

export const CareerApplication = () => {
  const location = useLocation();
  const { title } = location.state || {};

  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    fullName: "",
    emailId: "",
    contactNumber: "",
    residence: "",
    dob: "",
    recentEducation: "",
    recentJobTitle: "",
    recentCtc: "",
    noticePeriod: "",
    linkedin: "",
    cv: "",
    coverLetter: "",
    title: title || "",
  });
  const [cvFile, setCvFile] = useState(null);

  const themeImg = getDynamicImageUrl(
    "career/career_application_theme_img.svg"
  );
  const uploadCvIcon = getDynamicImageUrl(
    "career/career_application_uploadCv_icon.svg"
  );
  const illustrator = getDynamicImageUrl(
    "career/career_application_illustrator.svg"
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setCvFile(file);
    setFormData((prevState) => ({
      ...prevState,
      cv: file ? file.name : "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = formRef.current;
    const formData = new FormData(form);
    if (cvFile) {
      formData.append("cv", cvFile);
    }

    emailjs
      .sendForm(
        "service_blsw6qo",
        "template_uvoiwld",
        form,
        "iLlUHLgYIXKnUB1i4"
      )
      .then((result) => {
        console.log(result.text);
        alert("Form and CV submitted successfully!");

        setFormData({
          fullName: "",
          emailId: "",
          contactNumber: "",
          residence: "",
          dob: "",
          recentEducation: "",
          recentJobTitle: "",
          recentCtc: "",
          noticePeriod: "",
          linkedin: "",
          coverLetter: "",
          title: title || "",
        });
        setCvFile(null);
        form.reset();
      })
      .catch((error) => {
        console.log(error.text);
        alert("Failed to submit the form.");
      });
  };

  return (
    <div className="app_parent_container">
      <div className="app_container">
        <div className="app_container_1">
          <div className="app_container_1_1">
            <p>Welcome to Sci-Opportunity-verse</p>
            <p>
              Please only apply if you love being part of the team. We highly
              value that in a candidate.
            </p>
            <p>
              Please either fill in the application form below or send an email
              with following details and more to <b>career@sciverse.co.in</b>{" "}
            </p>
          </div>
          <div className="app_container_1_2">
            <form
              className="job_application_form"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
              ref={formRef}
            >
              {/* Form Fields */}
              <div className="form_row">
                <label>Full Name</label>
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form_row">
                <label>Email Address</label>
                <input
                  type="email"
                  name="emailId"
                  value={formData.emailId}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form_row">
                <label>Contact Number</label>
                <input
                  type="number"
                  name="contactNumber"
                  value={formData.contactNumber}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form_row">
                <label>Place of Residence</label>
                <input
                  type="text"
                  name="residence"
                  value={formData.residence}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form_row">
                <label>Date of Birth (DD/MM/YYYY)</label>
                <input
                  type="date"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form_row">
                <label>Most Recent Education</label>
                <input
                  type="text"
                  name="recentEducation"
                  value={formData.recentEducation}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form_row">
                <label>Most Recent Job Title</label>
                <input
                  type="text"
                  name="recentJobTitle"
                  value={formData.recentJobTitle}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form_row">
                <label>Most Recent CTC</label>
                <input
                  type="text"
                  name="recentCtc"
                  value={formData.recentCtc}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form_row">
                <label>Notice period in days</label>
                <input
                  type="text"
                  name="noticePeriod"
                  value={formData.noticePeriod}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form_row">
                <label>LinkedIn account URL</label>
                <input
                  type="text"
                  name="linkedin"
                  value={formData.linkedin}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form_row">
                <label>Upload CV</label>
                <div className="file-upload-wrapper">
                  <input
                    type="file"
                    id="cvFile"
                    name="cv"
                    onChange={handleFileChange}
                    className="file-input"
                    required
                  />
                  <label htmlFor="cvFile" className="custom-file-upload">
                    <div className="upload-icon">
                      <img src={uploadCvIcon} alt="Upload Icon" />
                    </div>
                    {cvFile ? (
                      <span>{cvFile.name}</span>
                    ) : (
                      <span>Click to upload your CV</span>
                    )}
                  </label>
                </div>
                <p className="file-instructions">
                  (Please make sure your CV is up to date and has all your
                  qualifications and skills included)
                </p>
              </div>
              <div className="form_row">
                <label>Write a cover letter</label>
                <textarea
                  name="coverLetter"
                  value={formData.coverLetter}
                  onChange={handleChange}
                  rows="8"
                  cols="2"
                  required
                />
                <p className="file-instructions">
                  (Please include other links like Portfolio, GitHub, Figma,
                  etc. in the cover letter section)
                </p>
              </div>
              <input type="hidden" name="title" value={formData.title} />
              <button>Submit Application</button>
            </form>
            {/* <div className="horizontal_line"></div> */}
          </div>
        </div>
        <div className="app_container_2">
          <img src={themeImg} />
          <div className="app_container_2_1">
            <img src={illustrator} />
          </div>
        </div>
      </div>
    </div>
  );
};
