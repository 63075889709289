import React, {useState, useEffect} from "react";
import { useLocation } from "react-router-dom";
import "../assets/styles/letsCollaborate.css";
import {getDynamicImageUrl} from "../components/specific/imageBlob";

export const LetsCollaborate = () => {
  const location = useLocation();

  const [formData, setFormData] = useState({
    fullName:"",
    emailId:"",
    companyName:"",
    phoneNumber:"",
    projectTimeline:"",
    requirements:"",
    sellProducts:"",
    supportNeeded:[],
    sectorOfDev:[],
  })
  const loc_icon = getDynamicImageUrl("letsCollaborate/location_icon.svg");
  const time_icon = getDynamicImageUrl("letsCollaborate/time_icon.svg");
  const hand_img = getDynamicImageUrl("letsCollaborate/hand_icon.svg");
  const wave_img = getDynamicImageUrl("letsCollaborate/wave_icon.svg");
  const theme_icon = getDynamicImageUrl("letsCollaborate/theme_icon.svg");
  const call_icon = getDynamicImageUrl("letsCollaborate/call.svg");
  const mail_icon = getDynamicImageUrl("letsCollaborate/mail.svg");



  useEffect(()=>{
    window.scrollTo(0,0);
  },[location]);

  const handleChange = (e) => {
    const { name, value} = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]:value
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, value } = e.target;
    if (e.target.checked) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: [...prevData[name], value],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: prevData[name].filter((item) => item !== value),
      }));
    }
  };

  // Checkbox change handler to dynamically manage the `required` attribute
// const handleCheckboxChange = (e) => {
//   const { name, value } = e.target;

//   // Update state logic for checkbox groups
//   if (e.target.checked) {
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: [...prevData[name], value],
//     }));
//   } else {
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: prevData[name].filter((item) => item !== value),
//     }));collaborate_section_1_2
//   }

//   // Dynamic validation for 'supportNeeded'
//   if (name === 'supportNeeded') {
//     const checkboxes = document.getElementsByName('supportNeeded');
//     const isChecked = Array.from(checkboxes).some((checkbox) => checkbox.checked);
//     checkboxes[0].required = !isChecked;  // Toggle `required` based on whether any is checked
//   }

//   // Dynamic validation for 'sectorOfDev'
//   if (name === 'sectorOfDev') {
//     const checkboxes = document.getElementsByName('sectorOfDev');
//     const isChecked = Array.from(checkboxes).some((checkbox) => checkbox.checked);
//     checkboxes[0].required = !isChecked;  // Toggle `required` based on whether any is checked
//   }
// };

  

  const handleSubmit = (e) => {
    e.preventDefault();
     // Check if at least one option is selected in 'supportNeeded'
  const supportNeededSelected = formData.supportNeeded.length > 0;

  // Check if at least one option is selected in 'sectorOfDev'
  const sectorOfDevSelected = formData.sectorOfDev.length > 0;

  // If neither group has a selected checkbox, show an alert and prevent form submission
  if (!supportNeededSelected || !sectorOfDevSelected) {
    alert('Please select at least one option to continue');
    return;
  }
    console.log(formData);
    setFormData({
      fullName:"",
      emailId:"",
      companyName:"",
      phoneNumber:"",
      projectTimeline:"",
      requirements:"",
      sellProducts:"",
      supportNeeded:[],
      sectorOfDev:[],
    })
  }




  return (
    <div className="collaborate_parent_container">
      <div className="collaborate_parent_container_1">
        <form
        onSubmit={handleSubmit}
        >

       
        <div className="collaborate_section_1">
          <div className="collaborate_section_1_1">
            <p className="font-Montserrat-700">Let’s Collaborate</p>
            <p className="font-Nunito-700">
              We're keen to understand the motivations behind your interest in
              collaboration.
            </p>
            <p className="font-Nunito-400">
              Could you please share what aspects of our expertise or offerings
              resonate with your company's goals or vision?{" "}
            </p>
            <p className="font-Nunito-400">
              Please fill in the form below as, we're eager to explore how our
              combined efforts could create synergies and drive impactful
              outcomes.
            </p>
          </div>
          <div className="collaborate_section_1_2">
            <label>What is your name ?</label>
            <br />
            <input type="text"
             autoComplete="off" 
             name="fullName"
             value={formData.fullName}
             onChange={handleChange}
             required
             />
            <br />
            <br />

            <label>Where can we write you an e-mail at?</label>
            <br />
            <input type="email"
            autoComplete="off"
            name="emailId"
            value={formData.emailId}

            onChange={handleChange}
            required
             />
            <br />
            <br />
            <label>What is the name of your company?</label>
            <br />
            <input type="text" autoComplete="off" 
             name="companyName"
             onChange={handleChange}
            value={formData.companyName}

             required
            />
            <br />
            <br />
            <label>What phone number can we reach you on?</label>
            <br />
            <input type="number" autoComplete="off" 
             name="phoneNumber"
            value={formData.phoneNumber}

             
             onChange={handleChange}
             required
            />
            <br />
            <br />
            <label>What is the desired timeline for the project?</label>
            <br />
            <input type="text" autoComplete="off" 
             name="projectTimeline"
             onChange={handleChange}
            value={formData.projectTimeline}

             required
            />
            <br />
            <br />
            <label>How would you describe your needs/requirements?</label>
            <br />
            <textarea rows="4" cols="63"
             name="requirements"
             onChange={handleChange}
            value={formData.requirements}

             required
            ></textarea>
            <br />
            <br />
            <button className="collaboration_btn font-Nunito-600">
              Send Collaboration Request
            </button>
            <div className="address_containerrr">
            <div className="address_containerr">
              <p>
                <img src={loc_icon} alt=""/>
                <span style={{textDecoration:"underline"}}>307, V-18, Balewadi High Street, Pune- 411045</span>
              </p>
              <p>
                <img src={time_icon} alt="" />
                <span>09:30 - 18:30</span>
              </p>
            
            </div>
            <div className="address_containerr_right">
              <p>
                <img src={mail_icon} alt="" />
                <span>info@sciverse.co.in</span>
              </p>
              <p>
                <img src={call_icon} alt="" />
                <span>+91 8240401969</span>
              </p>
            </div>
            </div>
            
          </div>
        </div>
        <div className="collaborate_section_2">
          <div className="collaborate_section_2_1">
            <img src={hand_img} alt="" />
          </div>
          <div className="collaborate_section_2_2">
            <div className="collaborate_section_2_2_1">
              <h3>Does your company currently sell products on the market?</h3>
              <label>
                <input
                  type="radio"
                  name="sellProducts"
                  value="Yes"
               
                  checked={formData.sellProducts === 'Yes'}
                  onChange={handleChange}
                  required
                />
                Yes
              </label><br /><br />
              <label>
                <input
                  type="radio"
                  name="sellProducts"
                  value="No"
                  checked={formData.sellProducts === "No"}
                  onChange={handleChange}
                  required
                />
                No
              </label><br /><br />
              <h3>What do you need support with?</h3>
              <label class="custom-checkbox_collaborate">
                <input 
                 type="checkbox" 
                 name="supportNeeded" 
                 value="Product Design and Development" 
                 checked={formData.supportNeeded.includes('Product Design and Development')}
                 onChange={handleCheckboxChange}
                />
                <span className="checkmark_collaborate"></span>Product Design and Development
              </label><br /><br />
              <label class="custom-checkbox_collaborate">
                <input
                 type="checkbox" 
                 name="supportNeeded" 
                 value="Regulation or Quality" 
                 checked={formData.supportNeeded.includes('Regulation or Quality')}
                 onChange={handleCheckboxChange}
                />
                <span className="checkmark_collaborate"></span>Regulation or Quality
              </label><br /> <br /><label class="custom-checkbox_collaborate">
                <input 
                type="checkbox" 
                name="supportNeeded" 
                value="Manufacturing" 
                checked={formData.supportNeeded.includes('Manufacturing')}
                onChange={handleCheckboxChange}
                />
                <span className="checkmark_collaborate"></span>Manufacturing
              </label><br /><br />

              <h3>What sector of development are you interested in?</h3>
      <label class="custom-checkbox_collaborate">
        <input
          type="checkbox"
          name="sectorOfDev"
          value="Artificial Intelligence"
          checked={formData.sectorOfDev.includes('Artificial Intelligence')}
          onChange={handleCheckboxChange}
        />
        <span className="checkmark_collaborate"></span>Artificial Intelligence
      </label><br /><br />
      <label class="custom-checkbox_collaborate">
        <input
          type="checkbox"
          name="sectorOfDev"
          value="Software or App"
          checked={formData.sectorOfDev.includes('Software or App')}
          onChange={handleCheckboxChange}
        />
        <span className="checkmark_collaborate"></span>Software or App
      </label><br /><br />
      <label class="custom-checkbox_collaborate">
        <input
          type="checkbox"
          name="sectorOfDev"
          value="Research and Development"
          checked={formData.sectorOfDev.includes('Research and Development')}
          onChange={handleCheckboxChange}
        />
        <span className="checkmark_collaborate"></span>Research and Development
      </label><br /><br />
      <label class="custom-checkbox_collaborate">
        <input
          type="checkbox"
          name="sectorOfDev"
          value="Marketing"
          checked={formData.sectorOfDev.includes('Marketing')}
          onChange={handleCheckboxChange}
        />
        <span className="checkmark_collaborate"></span>Marketing
      </label><br />
            </div>
            <div className="collaborate_section_2_2_2">
              <img src={wave_img} alt="" />
              <img src={theme_icon} alt="" />
            </div>
            {/* <div className="address_containerr_right">
              <p>
                <img src={mail_icon} alt="" />
                <span>info@sciverse.co.in</span>
              </p>
              <p>
                <img src={call_icon} alt="" />
                <span>+91 8240401969</span>
              </p>
            </div> */}
          </div>
        </div>
        </form>
      </div>
    </div>
  );
};
