import React from "react";
import "../assets/styles/privacyPolicyStyle.css";
import {getDynamicImageUrl} from "../components/specific/imageBlob";

const PrivacyPolicy = () => {

  const background_svg = getDynamicImageUrl("others/Group 525_1722327871817 3.webp");
  const background_vector = getDynamicImageUrl("others/Vector (1).webp");
  return (
    <>
      <div className="pp_container">
        <img src={background_svg} alt="bg_svg" className="background_svg" />
        <img
          src={background_vector}
          alt="bg_svg"
          className="background_vector"
        />
        <div className="pp_header_container_1">
          <label className="pp_header_label_1 font-Montserrat-700">Privacy Policy</label>
          <label className="pp_header_label_2 font-Nunito-700">Version : July 2024</label>
          <p className="font-Nunito-600">
            Sciverse Solutions Pvt Ltd is committed to protecting and respecting
            the privacy of your personal data. This Privacy Notice explains how
            the Sciverse company ("Sciverse Solutions Pvt Ltd" or "we") listed
            in the imprint of this website will process your personal data as
            the data controller in accordance with the EU General Data
            Protection Regulation (GDPR).
          </p>
        </div>
        <div className="pp_content_container_2">
          <div className="pp_content_subheader_container_1">
            <label className="font-Nunito-700">Processing of your personal data</label>
            <p className="font-Nunito-400">
              Sciverse Solutions Pvt Ltd processes your personal data as part of
              your use of our websites and online services as described in the
              following:
            </p>
          </div>
          <div className="pp_content_subheader_container_2">
            <label className="font-Nunito-700">Use of the Websites</label>
            <p className="font-Nunito-400">
              When using our websites, Sciverse processes information which is
              technically necessary for communication to take place and which
              may also be automatically sent to us by your browser or device,
              e.g., IP address, device type, browser type, pages visited, date
              and time of the request. We process this information to enable you
              to visit the websites, to improve and accelerate the presentation
              of the websites, to adapt and prepare the information offered for
              specific target groups and to design the websites according to
              user preferences. In addition, we process this information to
              ensure compliance with our Terms of Use, to exercise or defend
              ourselves against legal claims, and to prevent and counter
              fraudulent and similar actions, including attacks on our IT
              infrastructure. The legal basis for the processing is the
              safeguarding of the legitimate interests of Sciverse Solutions Pvt
              Ltd as website operator (Art. 6 para. 1 lit. f GDPR).
            </p>
          </div>
          <div className="pp_content_subheader_container_2">
            <label className="font-Nunito-700">
              Subscription to information and participation in surveys
            </label>
            <p className="font-Nunito-400">
              On our websites you can subscribe to various information, such as
              subscribe to newsletters or provide us with comments and feedback
              by participating in surveys. Sciverse processes the personal data
              that you have entered via the website, e.g., contact information
              such as first and last name, e-mail address, telephone number or
              comments. Sciverse uses this personal data to contact you and
              provide the requested information, to process your comments and
              feedback and to adapt and prepare the information offered for
              specific target groups. The processing of your personal data is
              based on your consent (Art. 6 para. 1 lit. a GDPR), the
              performance of a contract (Art. 6 para. 1 lit. b GDPR) or on our
              legitimate business interests in processing your comments and
              feedback (Art. 6 para 1 lit. f GDPR).
            </p>
          </div>
          <div className="pp_content_subheader_container_2">
            <label className="font-Nunito-700">Online Services</label>
            <p className="font-Nunito-400">
              When using our websites, Sciverse processes information which is
              technically necessary for communication to take place and which
              may also be automatically sent to us by your browser or device,
              e.g., IP address, device type, browser type, pages visited, date
              and time of the request. We process this information to enable you
              to visit the websites, to improve and accelerate the presentation
              of the websites, to adapt and prepare the information offered for
              specific target groups and to design the websites according to
              user preferences. In addition, we process this information to
              ensure compliance with our Terms of Use, to exercise or defend
              ourselves against legal claims, and to prevent and counter
              fraudulent and similar actions, including attacks on our IT
              infrastructure. The legal basis for the processing is the
              safeguarding of the legitimate interests of Sciverse Solutions Pvt
              Ltd as website operator (Art. 6 para. 1 lit. f GDPR).
            </p>
            <ul className="pp_list_container">
              <li className="font-Nunito-400">
                to provide the services and features of the Online Services and
                to manage your use; or to enable you to use the services and
                features of the Online Services;
              </li>
              <li className="font-Nunito-400">to verify your identity and enable user authentication</li>
              <li className="font-Nunito-400">
                to ensure compliance with our Terms of Use, establish or defend
                against legal claims, and prevent fraudulent or similar acts,
                including attacks on our IT infrastructure.
              </li>
            </ul>
            <p className="font-Nunito-400">
              The processing of your personal data is based on your consent
              (Art. 6 para. 1 lit. a GDPR), the performance of a contract (Art.
              6 para. 1 lit. b GDPR) or on our legitimate business interests as
              the provider of online services (Art. 6 para 1 lit. f GDPR).
            </p>
          </div>
          <div className="pp_content_subheader_container_2">
            <label className="font-Nunito-700">
              Processing of personal data related to your business relationship
              with Sciverse
            </label>
            <p className="font-Nunito-400">
              In the context of a business relationship with Sciverse, we
              process personal data of contact persons at customers, suppliers,
              sales partners, and partners ("business partners").
            </p>
          </div>
        </div>
        <div className="pp_content_container_3">
          <div className="pp_content_subcontainer_2">
            <label className="font-Nunito-700">Cookies and other similar technologies</label>
            <p className="font-Nunito-400">
              Sciverse and our partners use cookies and other similar
              technologies to operate the Sciverse websites and personalize
              content and ads.
            </p>
            <p className="font-Nunito-400">
              For more information about which cookies we use and how you can
              manage your cookie settings and disable certain types of tracking.
            </p>
          </div>
          <div className="pp_content_subcontainer_2">
            <label className="font-Nunito-700">Marketing, social media and events</label>
            <p className="font-Nunito-400">
              Sciverse processes your personal data when you register for and
              use the various Sciverse services, such as participating in events
              and webinars or subscribing to newsletters or communicating via
              various channels, including social media. We maintain publicly
              accessible profiles on various social networks. Your visit to
              these profiles triggers a variety of data processing operations.
            </p>
            <p className="font-Nunito-400">
              If our websites contain symbols from social media providers, we
              only use these to passively link to the pages of the respective
              providers.
            </p>
          </div>
          <div className="pp_content_subcontainer_2">
            <label className="font-Nunito-700">Mobile Apps</label>
            <p className="font-Nunito-400">
              Sciverse also offers a variety of applications that you can
              download to your mobile device ("Mobile Apps"). Some of these have
              their own privacy notice. These privacy notices can be viewed on
              the App Store prior to downloading the Mobile App and in the
              Mobile App itself.
            </p>
          </div>
          <div className="pp_content_subcontainer_2">
            <label className="font-Nunito-700">Recipients and transfer of personal data</label>
            <p className="font-Nunito-400">
              Sciverse may share your personal data with the following
              recipients, if and to the extent such transfer is necessary:
            </p>
            <ul className="pp_content_subcontainer_2_nested">
              <li className="font-Nunito-400">
                Sciverse companies which process personal data to support in
                fulfilling our contractual or legal obligations or internal
                functions such as customer services
              </li>
              <li className="font-Nunito-400">
                other recipients such as business partners or (IT-) service
                providers which process personal data as part of their service
                provision for Sciverse (e.g., hosting or IT maintenance and
                support services)
              </li>
              <li className="font-Nunito-400">
                third parties in connection with complying with legal
                obligations or establishing, exercising, or defending rights or
                claims or in relation to corporate transactions (e.g., for court
                and arbitration proceedings, to law enforcement authorities and
                regulators, to attorneys and consultants).
              </li>
            </ul>
            <p className="font-Nunito-400">
              Sciverse, as a global corporation, has affiliates located in
              countries around the world where Sciverse operates. Sciverse also
              uses service providers and third parties that may be based all
              over the world. Sometimes a recipient to whom Sciverse transfers
              personal data is in a country in which applicable laws do not
              provide the same level of data protection as the GDPR. In such
              cases and unless permitted otherwise by applicable law, Sciverse
              only transfers personal data if appropriate and suitable
              safeguards for the protection of personal data
            </p>
          </div>
          <div className="pp_content_subcontainer_2">
            <label className="font-Nunito-700">Storage Duration</label>
            <p className="font-Nunito-400">
              Sciverse will only process your personal data for as long as it is
              necessary to fulfil the purpose of the processing (e.g., until the
              matter you have contacted us with is completely clarified) or
              until you withdraw your consent, unless legal obligations or the
              establishment, exercise or defense of legal claims make a longer
              retention necessary.
            </p>
          </div>
          <div className="pp_content_subcontainer_2">
            <label className="font-Nunito-700">Your Rights</label>
            <p className="font-Nunito-400">
              Under the GDPR, you have specific rights in relation to your
              personal data. In particular, and subject to the statutory
              requirements, you may be entitled to:
            </p>
            <ul className="pp_content_subcontainer_2_nested">
              <li className="font-Nunito-400">
                obtain confirmation as to whether Sciverse processes personal
                data about you and, where that is the case, obtain access to
                your personal data processed by Sciverse as well as other
                information,
              </li>
              <li className="font-Nunito-400">
                obtain the rectification of your inaccurate personal data
                processed by Sciverse,
              </li>
              <li className="font-Nunito-400">
                obtain from Sciverse the erasure of your personal data processed
                by Sciverse,
              </li>
              <li className="font-Nunito-400">
                obtain from Sciverse restriction of processing of your personal
                data,
              </li>
              <li className="font-Nunito-400">
                obtain a copy of your personal information that you have
                provided to Sciverse or request that your personal information
                be transmitted to another recipient,
              </li>
              <li className="font-Nunito-400">
                object to the processing of your personal data by Sciverse on
                grounds relating to your particular situation insofar as the
                processing of your personal data is based on legitimate
                interests.
              </li>
            </ul>
            <p className="font-Nunito-400">
              If you have given Sciverse your consent to process your personal
              data, you have the right to withdraw your consent at any time with
              effect for the future, i.e., your withdrawal does not affect the
              lawfulness of the processing based on the consent before its
              withdrawal.
            </p>
            <p className="font-Nunito-400">
              Where Sciverse relies on its legitimate interests for processing
              personal data, Sciverse has determined that, after a balancing of
              interests, its legitimate interests are not overridden by your
              interests and rights or freedoms. More information on the
              balancing of interests can be obtained by contacting the Sciverse
              Data Privacy Organization.
            </p>
          </div>
          <div className="pp_content_subcontainer_3">
            <label className="font-Nunito-700">Changes to this Privacy Policy</label>
            <p className="font-Nunito-400">
              We keep our Privacy Notice under regular review to make sure it is
              up to date and accurate. The date of the last update can be found
              at the beginning of this Privacy Notice. We recommend that you
              visit this page regularly to check for any updates that may have
              been made.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
