import React, {useState, useEffect, useRef} from "react";
import {getDynamicImageUrl} from "../components/specific/imageBlob";

import { MultiColorRow } from "../components/specific/MultiColorRow";
import "../assets/styles/home.css";

import { useNavigate } from "react-router-dom";

export const Home = () => {
 

  // const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const illustrator = getDynamicImageUrl("home/illustratorImg.svg");
  const themeIMg = getDynamicImageUrl("home/themeImg.svg");
  const section_1_icon = getDynamicImageUrl("home/snap.svg");
  // const infinite_icon = getDynamicImageUrl("home/infinite_icon.svg");
  const section11_image = getDynamicImageUrl("home/career_image.svg");
  const mylab_image = getDynamicImageUrl("home/mylab_image.svg");
  const icon1 = getDynamicImageUrl("home/scilisa.png");
  const icon2 = getDynamicImageUrl("home/pointOfCare.svg");
  const icon3 = getDynamicImageUrl("home/myCount.png");
  const icon4 = getDynamicImageUrl("home/aiSoftware.svg");
  const icon5 = getDynamicImageUrl("home/ourCrew.svg");
  const icon6 = getDynamicImageUrl("home/opportunities.svg");
  const readmore_1 = getDynamicImageUrl("home/readmore_1.svg");
  const readmore_2 = getDynamicImageUrl("home/readmore_2.svg");
  const readmore_3 = getDynamicImageUrl("home/readmore_3.svg");


  const card1 = {
    borderRadius:"180px 100px 180px 30px",
    backgroundImage: `url(${readmore_1})`,
  }
  const card2 = {
    borderRadius:"180px 30px 180px 100px",
    backgroundImage: `url(${readmore_2})`,
  }
  const card3 = {
    borderRadius:"100px 180px 30px 180px",
    backgroundImage: `url(${readmore_3})`,
  }





  const navigateLink = (link) => {
    window.open(link, "_blank");
  };

  
  

  const handleCardClick = (event) => {
    const link = event.currentTarget.dataset.link;
    if (link) {
      navigateLink(link);
    }
  };

  const handleScrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth"
    });
  };

  const wordsForRow1 = [
    "Innovation",
    "Collaboration",
    "Empathy",
    "Excellence",
    "Accessibility",
    "Focus and Impact",
    "Professionalism",
    "Curiosity",
    "Smart-Work",
    "User-centric",
    "Quality",
    "Value Addition",
    "Team-spirit",
    "Commitment",
    "Science and Technology",
    "Passion",
    "Ideation",
  ];

  const colorsForRow1 = [
    "#1770A2",
    "#3CA2DB",
    "#0A1572",
    "#A1D1FE",
    "#066ECD",
    "#0220BD",
    "50D4E7",
    "#7E8DDC",
    "#7EA6E1",
    "#0070C0",
    "#2A7CC7",
    "#004382",
    "#054FA5",
    "#1A7FDD",
    "27258E",
    "#3E75A8",
    "#CCD7FE",
  ];

  const wordsForRow2 = [
    "Professionalism",
    "Curosity",
    "Smart-work",
    "User-centric",
    "Quality",
    "Value Addition",
    "Team-spirit",
    "Commitment",
    "Science and Technology",
    "Passion",
    "Ideation",
    "Innovation",
    "Collaboration",
    "Empathy",
    "Excellence",
    "Accessibility",
    "Focus and Impact",
  ];
  const colorsForRow2 = [
    "#50D4E7",
    "#7E8DDC",
    "#88DBFF",
    "#00A9C0",
    "#2A7CC7",
    "#274663",
    "#054FA5",
    "#1A7FDD",
    "#27258E",
    "#3E75A8",
    "#CCD7FE",
    "#004382",
    "#3CA2DB",
    "#0A1572",
    "#96C0FE",
    "#066ECD",
    "#0220BD",
  ];

  const wordsForRow3 = [
    "Team-spirit",
    "Commitment",
    "Science and Technology",
    "Passion",
    "Ideation",
    "Innovation",
    "Collaboration",
    "Empathy",
    "Excellence",
    "Accessibility",
    "Focus and Impact",
    "Professionalism",
    "Curiosity",
    "Smart-work",
    "User-centric",
    "Quality",
    "Value addition",
  ];
  const colorsForRow3 = [
    "#054FA5",
    "#1A7FDD",
    "#27258E",
    "#3E75A8",
    "#CCD7FE",
    "#004382",
    "#3CA2DB",
    "#0A1572",
    "#96C0FE",
    "#066ECD",
    "#0220BD",
    "#50D4E7",
    "#7E8DDC",
    "#88DBFF",
    "#00A9C0",
    "#2A7CC7",
    "#274663",
  ];

  return (
    <div className="home_parent">
      <div className="home_parent_container">
        <div className="home_container_1">
          {/* Section 1 Starts */}
          <div className="home_container_1_1">
            <div className="home_container_1_1_1">
              <img className="img_img" src={section_1_icon} alt="" />
              <div className="home_container_1_1_1_parent_container">
                <span className="font-Nunito-800">MedTech</span>
                {/* <br /> */}
                <span className="re_envisioned font-Nunito-600">Re-envisioned</span>
                <div
                  className="home_container_1_1_2 font-Nunito-700 "
                  // onClick={handleScrollToBottom}
                  onClick={()=>navigate("/contact")}
                >
                  Contact Us
                </div>
                <img src={illustrator} />
                <img style={{ height: "70%" }} src={themeIMg} />
              </div>
              <div className="home_container_1_1_1_parent_container_2">
                <div className="home_container_1_1_1_parent_container_2_1">
                  <div className="shape_1"></div>
                  <div className="shape_2"></div>
                  <div className="shape_3"></div>
                </div>
                <div className="home_container_1_1_1_parent_container_2_2">
                  <div className="shape_4"></div>
                  <div className="shape_5"></div>
                  <div className="shape_6"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="home_container_1_8">
            <div className="home_container_1_8_1">
              <div className="item">
                <div className="item_1 iteemm_1">
                  <img src={icon1} alt="" />
                </div>
                <div className="item_2">
                  <span className="font-Montserrat-700">SciLisa</span>
                  <p className="font-Nunito-400 ">
                  {/* Step into the future of immunoassays with SciLisa, our advanced digital ELISA platform. */}
                  Step into the future of Ultra-Sensitive Biomarker detection with SciLisa.
                  </p>
                  <button className="font-Nunito-700 four_lines">
                    Know more
                  </button>
                </div>
              </div>
              <div className="item">
                <div className="item_1 iteemm_1">
                  <img src={icon2} alt="" />
                </div>
                <div className="item_2">
                  <span className="font-Montserrat-700">Point-of-Care</span>
                  <p className="font-Nunito-400 ">
                  With our modular point-of-care technologies, we transform healthcare
                  </p>
                  <button className="font-Nunito-700">Know more</button>
                </div>
              </div>
              <div className="item">
                <div className="item_1 iteemm_1">
                  <img src={icon3} alt="" />
                </div>
                <div className="item_2 mycount_express">
                  <span className="font-Montserrat-700 mycount_text">MyCount Xpress</span>
                  <p className="font-Nunito-400 ">
                  Embrace the future of instant CBC testing from finger pricks, right at doctors' desks.
                  </p>
                  <button
                    className="font-Nunito-700 four_line"
                    // style={{marginTop:"20px"}}
                  >
                    Know more
                  </button>
                </div>
              </div>
              <div className="item">
                <div className="item_1 iteemm_1">
                  <img src={icon4} alt="" />
                </div>
                <div className="item_2 mycount_express">
                  <span className="font-Montserrat-700">AI and Software</span>
                  <p className="font-Nunito-400 ">
                    We harness outstanding AI capabilities for various medical
                    and research solutions.
                  </p>
                  <button
                    className="font-Nunito-700 four_lines"
                    onClick={() => navigate("/aisoftware")}

                     style={{cursor:"pointer"}}
                  >
                    Know more
                  </button>
                </div>
              </div>
              <div className="item">
                <div className="item_1 iteemm_1">
                  <img src={icon5} alt="" />
                </div>
                <div className="item_2">
                  <span className="font-Montserrat-700">Our People</span>
                  <p className="font-Nunito-400 ">
                    We’re proud of our diverse team that breaks stereotypes
                    everyday.
                  </p>
                  <button
                    className="font-Nunito-700"
                    // onClick={() => navigate("/ourteam")}
                    style={{cursor:"not-allowed"}}

                  >
                    Know more
                  </button>
                </div>
              </div>
              <div className="item">
                <div className="item_1 iteemm_1">
                  <img src={icon6} alt="" />
                </div>
                <div className="item_2">
                  <span className="font-Montserrat-700">Opportunities</span>
                  <p className="font-Nunito-400 ">
                    Make your career, interesting, challenging and ever-growing.
                  </p>
                  <button
                    // style={{ cursor: "pointer" }}
                    className="font-Nunito-700"
                    // onClick={() => navigate("/career")}
                  >
                    Know more
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Section 1 Ends */}

          <div className="home_container_1_7">
            <div className="home_container_1_7_1">
              <div className="home_container_1_7_1_1 font-Nunito-400">
                As we continue to push the boundaries of innovation and shape
                the future of healthcare, we invite you to join us on our
                journey of transformative impact. By collaborating with us, you
                will become a vital part of a mission dedicated to improving
                patient outcomes, empowering caregivers, and uplifting
                communities around the world. Whether you're an industry expert,
                a research partner, or an organization with a shared vision,
                together we can utilize our collective expertise to advance
                groundbreaking solutions. Let’s work hand in hand to create a
                more compassionate future for all.
              </div>
              <div
                className="home_container_1_7_1_2 font-Nunito-700"
                // onClick={() => navigate("/collaborate")}
                style={{cursor:"not-allowed"}}

              >
                Collaborate
              </div>
            </div>
          </div>

          {/* Section 2 Starts */}
{/* 
          <div className="home_container_1_2">
            <h1 className="font-Montserrat-700">About us</h1>
            <div className="font-Nunito-400 ">
              Our name embodies our mission: to explore the boundless expanse of
              the Scientific Realm and harness its potential to transform
              healthcare. As a trailblazing MedTech company headquartered in
              India, we are committed to pushing the boundaries of innovation
              and reshaping the healthcare landscape.
              <br /> <br />
              With a firm belief in healthcare as a fundamental human right, we
              strive to leverage cutting-edge technologies such as AI,
              microfluidics, and more to break down barriers and ensure
              equitable access to quality care for all. Our journey is fueled by
              a passion for discovery, a relentless pursuit of excellence, and a
              profound sense of compassion and care.
            </div>
          </div>

          <div className="home_container_1_3">
            <h1 className="font-Montserrat-700">Our Mission</h1>
            <div className="font-Nunito-400 ">
              At Sciverse, we believe that healthcare should be empowering,
              inclusive, and compassionate. Our mission is to leverage the power
              of innovation to transform healthcare delivery, improve patient
              outcomes, and enhance the overall healthcare experience.
            </div>
          </div>

          <div className="home_container_1_4">
            <h1 className="font-Montserrat-700">Our Journey</h1>
            <div className="font-Nunito-400 ">
              Our journey began with a bold vision and a commitment to
              excellence. From our humble beginnings, we have grown into a
              formidable force in the MedTech landscape, fueled by the passion
              and dedication of our visionary founders and talented team. <br />
              Along the way, we have encountered challenges, milestones, and
              moments of inspiration. Each experience has shaped our journey and
              reinforced our belief in the transformative potential of
              technology to drive positive change in healthcare.
            </div>
          </div>

          <div className="home_container_1_5">
            <p> In our journey thus far, we have had:</p>
            <div className="home_container_1_5_1">
              <div className="home_container_1_5_1_1">
                <p>08</p>
                <span className="font-Nunito-700">Happy Clients</span>
              </div>
              <div
                className="home_container_1_5_1_1"
                style={{ marginTop: "45px" }}
              >
                <img src={infinite_icon} alt="" />
                <span className="font-Nunito-700">Dedication</span>
              </div>
              <div className="home_container_1_5_1_1">
                <p>10</p>
                <span className="font-Nunito-700">Products made</span>
              </div>
              <div className="home_container_1_5_1_1">
                <p>2000</p>
                <span className="font-Nunito-700">Cups of coffee</span>
              </div>
            </div>
          </div> */}


          <div className="home_container_updated_1" >
            <div className="home_container_updated_1_1 about_us_container font-Nunito-400">
              <p className="font-Montserrat-700">About Us</p>
              At the core of our identity lies an ambitious mission: to explore
              the uncharted territories of science and unlock its vast potential
              to transform healthcare. As an ever-growing MedTech company
              headquartered in India, we boldly challenge the status quo of
              innovation, daring to redefine the healthcare landscape. We hold
              firm in our belief that access to quality healthcare is a
              fundamental human right. Harnessing all the groundbreaking Point
              of Care technologies - from artificial intelligence to
              microfluidics - we strive to dismantle barriers and foster
              equitable access for all. Our journey is fueled by a fervent
              passion for discovery, a steadfast commitment to excellence, and
              an intrinsic sense of compassion. Together, we envision a future
              where everyone experiences the life-changing impact of healthcare,
              igniting hope and enriching lives across the globe. Join us on
              this extraordinary quest toward a healthier tomorrow—because
              together, we can redefine what’s possible.
            </div>
            <div className="home_container_updated_1_1 our_mission_container font-Nunito-400">
              <p className="font-Montserrat-700">Our Mission</p>
              At Sciverse, we envision healthcare as a sanctuary of empowerment,
              inclusivity, and compassion. Our mission is not just to innovate;
              it is to revolutionize the very essence of healthcare delivery. We
              harness cutting-edge technologies to improve the patient outcomes,
              enhance the overall healthcare experience, and ensure that every
              interaction is meaningful. In a world where health can often feel
              like a privilege, we are committed to making it a universal right,
              accessible to all. Our approach is driven by a blend of ingenuity
              and empathy, creating solutions that resonate with the needs of
              patients and providers alike. Every breakthrough we achieve is a
              step toward an inclusive healthcare system that uplifts
              communities, fosters wellness, and ultimately transforms lives.
              Together, we aim to empower individuals, strengthen initiatives,
              and build a healthier, more compassionate world—one innovation at
              a time.
            </div>
            <div className="home_container_updated_1_1 our_journey_container">
              <div className="home_container_updated_1_1_1  font-Nunito-400">
                <p className="font-Montserrat-700">Our Journey</p>
                Our journey started with a bold vision and a commitment to
                excellence. What began as a modest initiative has grown into a
                driving force for healthcare innovation, fueled by the passion
                and ingenuity of our founders and dedicated team. We have faced
                challenges that tested our resolve, celebrated milestones that
                ignited our ambition, and embraced moments that inspired our
                creativity. Each experience has shaped our evolution—through
                research, development, and collaboration—bringing us ever closer
                to our mission of creating a healthier future.
                <br />
                <br />
                {/* <b> */}
                In our journey thus far, we have had:
                {/* </b> */}
              </div>
              <div className="home_container_updated_1_1_2">
                <div class="stat">
                  <span class="number font-Nunito-900">02</span>
                  <span class="description font-Nunito-600">Worldwide Hubs</span>
                </div>
                <div class="stat">
                  <span class="icon">∞</span>
                  <span class="description misplace font-Nunito-600">Dedication</span>
                </div>
                <div class="stat">
                  <span class="number font-Nunito-900">10</span>
                  <span class="description font-Nunito-600">Solutions made</span>
                </div>
                <div class="stat">
                  <span class="number font-Nunito-900">8000+</span>
                  <span class="description font-Nunito-600">Cups of coffee</span>
                </div>
              </div>
            </div>
          </div>
 <div className="our_values_empty_container">
 <div className="home_container_1_6">
            <h1>Our Values</h1>
            <div className="home_container_1_6_1">
              <MultiColorRow words={wordsForRow1} colors={colorsForRow1} />
              <MultiColorRow
                words={wordsForRow2}
                colors={colorsForRow2}
                // isHovered={isHovered}
              />
              <MultiColorRow
                words={wordsForRow3}
                colors={colorsForRow3}
                // isHovered={isHovered}
              />
            </div>
          </div>
 </div>


        


          <div className="home_container_1_9">
            <div className="home_container_1_9_1">
              <div className="home_container_1_9_1_1 font-Nunito-400">
              Are you passionate about science and eager to share your thoughts? If you have a few moments to spare, we would greatly appreciate your participation in a brief survey. Whether you’re a student, a professional, or just someone with a curiosity for science, your insights will help us enhance our programs and better understand the interests and needs of our community.
              </div>
              <div
                className="home_container_1_9_1_2 font-Nunito-700"
                // onClick={() => navigate("/survey")}
                style={{cursor:"not-allowed"}}

              >
                Participate
              </div>
            </div>
          </div>

          <div className="home_container_1_10">
            <div className="home_container_1_10_1">
              <div className="home_container_1_10_1_1"
              style={card1}
              >
                <div className="home_container_1_10_1_1_1">
                  <div className="home_container_1_10_1_1_1_1 font-Montserrat-400 ">
                    <span></span>The Indian Institute of
                    <br /> Technology (IIT) Goa, has <br />{" "}
                    <span
                      style={{ display: "inline-block", width: "20px" }}
                    ></span>
                    signed an MoU with <br />
                    <span
                      style={{ display: "inline-block", width: "22px" }}
                    ></span>{" "}
                    Sciverse Solutions
                  </div>
                  <div
                    className="read_more"
                    data-link="https://www.thehindubusinessline.com/info-tech/sciverse-solutions-signs-mou-with-iit-goa-to-buildloc-and-ivd-microfluidics-platforms/article66298027.ece"
                    onClick={handleCardClick}
                  >
                    Read More
                  </div>
                  <div className="read_more_platform font-Montserrat-600">
                  The Hindu Business Line
                  </div>
                </div>
              </div>
              <div className="home_container_1_10_1_1"
              style={card2}
              
              >
                <div className="home_container_1_10_1_1_1">
                  <div className="home_container_1_10_1_1_1_1 font-Montserrat-400">
                    <span
                      style={{ display: "inline-block", width: "20px" }}
                    ></span>
                   Sciverse opens new
                    <br />
                    <span
                      style={{ display: "inline-block", width: "24px" }}
                    ></span>{" "}
                    engineering R&D <br />{" "}
                    <span
                      style={{ display: "inline-block", width: "40px" }}
                    ></span>
                    centre in Pune <br />
                    <span
                      style={{ display: "inline-block", width: "80px" }}
                    ></span>
                    {/* Incorporation Day */}
                  </div>
                  <div
                    className="read_more"
                    data-link="https://www.biospectrumindia.com/news/20/25038/sciverse-opens-new-engineering-rd-centre-in-pune.html"
                    onClick={handleCardClick}
                  >
                    Read More
                  </div>
                  <div className="read_more_platform font-Montserrat-600">
                  Pharma Biz
                  </div>
                </div>
              </div>
              <div className="home_container_1_10_1_1"
              style={card3}
              
              >
                <div className="home_container_1_10_1_1_1">
                  <div className="home_container_1_10_1_1_1_1 font-Montserrat-400">
                    <span></span>Sciverse Expands Global<br />
                    <span
                      style={{ display: "inline-block", width: "20px" }}
                    ></span>
                    Reach with Advanced<br />{" "}
                    <span
                      style={{ display: "inline-block", width: "50px" }}
                    ></span>
                    Point-of-Care
                    <br />
                    <span
                      style={{ display: "inline-block", width: "50px" }}
                    ></span>
                    Technologies
                  </div>
                  <div
                    className="read_more"
                    data-link="https://apnews.com/press-release/ein-presswire-newsmatics/medical-technology-medical-devices-34a665c730c9bfdf60bdc67db415b11e"
                    onClick={handleCardClick}
                  >
                    Read More
                  </div>
                 <div className="read_more_platform font-Montserrat-600">
                 AP News
                 </div>
                </div>
              </div>
            </div>
          </div>

          <div className="home_container_1_11">
            <div className="home_container_1_11_1">
              <div className="home_container_1_11_1_1">
                <img src={section11_image} alt="" />
              </div>
              <div className="home_container_1_11_1_2">
                <div className="home_container_1_11_1_2_1 font-Montserrat-700">
                Looking for your next fulfilling job opportunity??? Join Sciverse and Build the future of Healthcare!
                </div>
                <div className="home_container_1_11_1_2_2 font-Nunito-400">
                At Sciverse, we’re redefining health innovation. Explore exciting career opportunities that empower you to drive change in healthcare and technology, making better health accessible for everyone.
                </div>
                <div
                  className="home_container_1_11_1_2_3"
                  style={{cursor:"not-allowed"}}

                  // onClick={() => navigate("/career")}
                >
                  Careers With Us
                </div>
              </div>
            </div>
          </div>
          <div className="home_container_1_12">
            <div className="home_container_1_12_1">
              <div className="home_container_1_12_1_1">
                <div className="font-Nunito-400">
                  We thank our strategic investor and partner, Mylab Discovery
                  Solutions Private Limited, for their support, guidance, and
                  encouragement.
                </div>
              </div>
              <div className="home_container_1_12_1_2">
                <img src={mylab_image} alt="" />
              </div>
            </div>
          </div>

          {/* Section 2 Ends  */}
        </div>

        <div className="home_container_2">
          {/* <img src={illustrator} loading="lazy" alt="" /> */}
        </div>
      </div>
    </div>
  );
};
